import { format } from 'date-fns';
import { TFunction } from 'i18next';

import { Activity } from '../tracking/activity';
import { Cell } from '../tracking/cell';

import { KEY_LAYOUT } from './locale.storage.keys';
import { get } from './storage-impl';

export function initialLayout(t?: TFunction) {
    return (
        '{"v":"2","format":"time.emphasize","r":{"l":"v","s":[' +
        '{"l":"h","s":[{"n":"' +
        t('work') +
        '","c":"#4aff00"},{"n":"' +
        t('sleep') +
        '","c":"#ffaa11"}]},' +
        '{"l":"v","s":[{"n":"' +
        t('eat') +
        '","c":"#00afff"},{"n":"' +
        t('leasure') +
        '","c":"#308a11"}]}]}}'
    );
}

export function getAllActivities(layout: string): Activity[] {
    const all: Activity[] = [];
    const root = Cell.fromJson(layout);
    const recurse = (cell: Cell) => {
        if (cell.s) {
            recurse(cell.s[0]);
            recurse(cell.s[1]);
        } else {
            all.push({ n: cell.n, c: cell.c } as Activity);
        }
    };
    recurse(root);
    return all;
}

export function downloadLayout(t?: TFunction) {
    const layout = get(KEY_LAYOUT) ?? initialLayout(t);
    const blob = new Blob([layout], { type: 'application/json;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'time-emphasize_layout_' + format(new Date(), 'yyyy-MM-dd_HH-mm-ss') + '.json');
    a.click();
}
