import EventEmitter from 'events';

import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Divider, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { downloadInvoices, resetInvoicesAndBuyers } from '../invoice/invoice-utils';
import {
    ACTION_IMPORT_EVENTS,
    ACTION_IMPORT_INVOICES,
    ACTION_IMPORT_LAYOUT,
    ACTION_OPEN_DIALOG,
    ACTION_UPDATE_INVOICES,
} from '../utils/action.keys';
import { downloadEvents } from '../utils/event-utils';
import { downloadLayout } from '../utils/layout-utils';

type ComponentProps = {
    open: boolean;
    onClose: () => void;
    clearEvents: () => void;
    appAction: EventEmitter;
};

export function MaintenanceDialog({ open, onClose, clearEvents, appAction }: ComponentProps) {
    const { t } = useTranslation();
    const messageBar = useSnackbar();

    return (
        <Dialog
            onClose={onClose}
            open={open}
            onKeyDown={(event) => {
                if (event.key === 'Escape') {
                    onClose();
                    event.preventDefault();
                }
            }}
        >
            <DialogTitle sx={{ m: 0, mr: 6, p: 2 }}>
                {t('maintenance.dialog.title')}{' '}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <List sx={{ pt: 0 }}>
                <ListItemButton
                    data-testid="export.csv"
                    onClick={() => {
                        downloadEvents();
                        onClose();
                    }}
                >
                    <ListItemIcon>
                        <FileDownloadIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('export.csv')} />
                </ListItemButton>
                <ListItemButton
                    data-testid="clear.csv"
                    onClick={() => {
                        clearEvents();
                        onClose();
                    }}
                >
                    <ListItemIcon>
                        <CleaningServicesIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('clear.csv')} />
                </ListItemButton>
                <ListItemButton
                    data-testid="import.csv"
                    onClick={() => {
                        appAction.emit(ACTION_IMPORT_EVENTS);
                        onClose();
                    }}
                >
                    <ListItemIcon>
                        <FileUploadIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('import.csv')} />
                </ListItemButton>
            </List>
            <Divider />
            <List sx={{ pt: 0 }}>
                <ListItemButton
                    data-testid="export.layout"
                    onClick={() => {
                        downloadLayout(t);
                        onClose();
                    }}
                >
                    <ListItemIcon>
                        <FileDownloadIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('export.layout')} />
                </ListItemButton>
                <ListItemButton
                    data-testid="import.layout"
                    onClick={() => {
                        appAction.emit(ACTION_IMPORT_LAYOUT);
                        onClose();
                    }}
                >
                    <ListItemIcon>
                        <FileUploadIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('import.layout')} />
                </ListItemButton>
            </List>
            <Divider />
            <List sx={{ pt: 0 }}>
                <ListItemButton
                    data-testid="export.invoices"
                    onClick={() => {
                        downloadInvoices();
                        onClose();
                    }}
                >
                    <ListItemIcon>
                        <FileDownloadIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('export.invoices')} />
                </ListItemButton>
                <ListItemButton
                    data-testid="clear.invoices"
                    onClick={() => {
                        appAction.emit(ACTION_OPEN_DIALOG, {
                            title: t('confirm.clear.invoices.title'),
                            text: t('confirm.clear.invoices.text'),
                            ok: t('ok'),
                            cancel: t('abort'),
                            action: (ok: boolean) => {
                                if (ok) {
                                    resetInvoicesAndBuyers();
                                    appAction.emit(ACTION_UPDATE_INVOICES);
                                    messageBar.enqueueSnackbar(t('success') + ': ' + t('clear.csv'), { variant: 'success' });
                                }
                            },
                        });
                        onClose();
                    }}
                >
                    <ListItemIcon>
                        <CleaningServicesIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('clear.invoices')} />
                </ListItemButton>
                <ListItemButton
                    data-testid="import.invoices"
                    onClick={() => {
                        appAction.emit(ACTION_IMPORT_INVOICES);
                        onClose();
                    }}
                >
                    <ListItemIcon>
                        <FileUploadIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('import.invoices')} />
                </ListItemButton>
            </List>
        </Dialog>
    );
}
