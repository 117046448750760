import { setDefaultOptions } from 'date-fns';
import i18n, { use, changeLanguage } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import cs from './assets/i18n/cs.json';
import da from './assets/i18n/da.json';
import de from './assets/i18n/de.json';
import el from './assets/i18n/el.json';
import en from './assets/i18n/en.json';
import es from './assets/i18n/es.json';
import fr from './assets/i18n/fr.json';
import ga from './assets/i18n/ga.json';
import hi from './assets/i18n/hi.json';
import hu from './assets/i18n/hu.json';
import iw from './assets/i18n/iw.json';
import ja from './assets/i18n/ja.json';
import ko from './assets/i18n/ko.json';
import nl from './assets/i18n/nl.json';
import no from './assets/i18n/no.json';
import pl from './assets/i18n/pl.json';
import ru from './assets/i18n/ru.json';
import sv from './assets/i18n/sv.json';
import tr from './assets/i18n/tr.json';
import uk from './assets/i18n/uk.json';
import vi from './assets/i18n/vi.json';
import zh from './assets/i18n/zh.json';

export const supportedLngs = [
    'de', // Deutsch
    'en', // Englisch
    'fr', // Französisch
    'es', // Spanisch
    'zh', // Zhuang - Chinesisch
    'pl', // Polnisch
    'iw', // Hebräisch
    'hi', // Hindi
    'el', // Griechisch
    'da', // Dänisch
    'ga', // Irisch
    'ja', // Japanisch
    'ko', // Koreanisch
    'nl', // Niederländisch
    'no', // Norwegisch
    'ru', // Russisch
    'sv', // Schwedisch
    'cs', // Tschechisch
    'tr', // Türkisch
    'uk', // Ukrainisch
    'hu', // Ungarisch
    'vi', // Vietnamesisch
];

export const setupI18n = () => {
    setDefaultOptions({ weekStartsOn: 1 });
    // eslint-disable-next-line
    use(initReactI18next)
        .use(LanguageDetector)
        .init({
            // debug: true,
            resources: { de, en, fr, es, zh, pl, iw, hi, el, da, ga, ja, ko, nl, no, ru, sv, cs, tr, uk, hu, vi },
            detection: { order: ['localStorage', 'querystring', 'navigator'], lookupQuerystring: 'lng' },
            fallbackLng: 'en',
            supportedLngs,
            nonExplicitSupportedLngs: true,
            defaultNS: 'i18n',
            ns: ['i18n'],
            interpolation: {
                escapeValue: false, // react already protects against xss
            },
        });

    changeLanguage();

    i18n.on('languageChanged', (lng) => {
        const parentLng = lng?.split(/(_|-)/)[0]; // example: en-GB -> en
        window.document.documentElement.lang = parentLng;
    });
};
