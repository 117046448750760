import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CarpenterIcon from '@mui/icons-material/Carpenter';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import EditIcon from '@mui/icons-material/Edit';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ConnectionMode } from '../connect/connection-mode';
import { Sync } from '../connect/sync';
import { CellMode } from '../tracking/cell-mode';

type ComponentProps = {
    mode: CellMode;
    setMode: (mode: CellMode) => void;
    sync: Sync;
};

export function ModeBar({ mode, setMode, sync }: ComponentProps) {
    const { t } = useTranslation();
    return (
        <ToggleButtonGroup color="standard" value={mode} exclusive onChange={(event, value) => !!value && setMode(value)}>
            <Tooltip arrow key="jump" title={t('mode.jump')}>
                <ToggleButton aria-label={t('mode.jump')} data-testid="mode.jump" value={CellMode.JUMP} sx={{ border: 0 }}>
                    <AccessTimeIcon color="primary" />
                    <span className="sm-hide">{t('mode.jump')}</span>
                </ToggleButton>
            </Tooltip>
            <Tooltip arrow key="split" title={t('mode.split')}>
                <ToggleButton aria-label={t('mode.split')} data-testid="mode.split" value={CellMode.SPLIT} sx={{ border: 0 }}>
                    <CarpenterIcon color="primary" />
                    <span className="sm-hide">{t('mode.split')}</span>
                </ToggleButton>
            </Tooltip>
            <Tooltip arrow key="rename" title={t('mode.rename')}>
                <ToggleButton aria-label={t('mode.rename')} data-testid="mode.rename" value={CellMode.RENAME} sx={{ border: 0 }}>
                    <EditIcon color="primary" />
                    <span className="sm-hide">{t('mode.rename')}</span>
                </ToggleButton>
            </Tooltip>
            <Tooltip arrow key="color" title={t('mode.color')}>
                <ToggleButton aria-label={t('mode.color')} value={CellMode.COLOR} sx={{ border: 0 }}>
                    <ColorLensIcon color="primary" />
                    <span className="sm-hide">{t('mode.color')}</span>
                </ToggleButton>
            </Tooltip>
            <Tooltip arrow key="remove" title={t('mode.remove')}>
                <ToggleButton aria-label={t('mode.remove')} data-testid="mode.remove" value={CellMode.REMOVE} sx={{ border: 0 }}>
                    <CancelPresentationIcon color="primary" />
                    <span className="sm-hide">{t('mode.remove')}</span>
                </ToggleButton>
            </Tooltip>
            {Object.keys(sync.pairings)
                .filter((k) => sync.pairings[k].active && sync.pairings[k].sync === ConnectionMode.QRCODE_PUSH)
                .map((k) => (
                    <Tooltip arrow key={'qr_' + sync.pairings[k].pairing} title={t('mode.sync') + sync.pairings[k].displayName}>
                        <ToggleButton
                            aria-label={t('mode.sync') + sync.pairings[k].displayName}
                            value={CellMode.QR}
                            onClick={() => (mode === CellMode.QR ? setMode(CellMode.JUMP) : setMode(CellMode.QR))}
                            sx={{ border: 0 }}
                        >
                            <QrCode2Icon color="primary" />
                            <span className="sm-hide">{t('mode.sync') + sync.pairings[k].displayName}</span>
                        </ToggleButton>
                    </Tooltip>
                ))}
        </ToggleButtonGroup>
    );
}
