import { Autocomplete, Box, FormControl, FormHelperText, TextField } from '@mui/material';
import currencyCodes from 'currency-codes';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PREFIX_BUYER } from '../utils/locale.storage.keys';
import { get, getKeys } from '../utils/storage-impl';

import { CountrySelect } from './CountrySelect';
import { getCurrencySign } from './currencySuggestions';
import { Address } from './Invoice';
import { InvoiceContext } from './Invoicing';

export function Buyer() {
    const { t } = useTranslation();

    const [buyers, setBuyers] = useState<string[]>([]);
    const context = React.useContext(InvoiceContext);

    useMemo(() => {
        if (context.version > -1) {
            // dummy condition, always true
            setBuyers(
                getKeys()
                    .filter((k) => k.startsWith(PREFIX_BUYER))
                    .map((k) => JSON.parse(get(k)).buyerName)
                    .sort()
            );
        }
    }, [setBuyers, context.version]);

    return (
        <Box sx={{ marginBottom: 8 }}>
            <FormControl sx={{ m: 1, minWidth: 260 }}>
                <TextField
                    label={t('invoicing.oration.label')}
                    sx={{ m: 1 }}
                    value={context.invoice.oration}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newInvoice = { ...context.invoice, oration: e.target.value };
                        context.setInvoice(newInvoice);
                    }}
                />
                <FormHelperText>{t('invoicing.oration.hint')}</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 2, minWidth: 120 }}>
                <Autocomplete
                    sx={{ width: '10rem' }}
                    id="buyer"
                    freeSolo
                    options={buyers}
                    value={context.invoice.buyerName}
                    onChange={(event: React.SyntheticEvent, newValue: string | null) => {
                        const b = JSON.parse(get(PREFIX_BUYER + (newValue ?? '').replaceAll(/[^0-9a-zA-Z\.]/g, '_')));
                        if (b) {
                            const newInvoice = {
                                ...context.invoice,
                                oration: b.oration,
                                buyerName: b.buyerName,
                                buyerAddress: b.buyerAddress,
                                buyerEmail: b.buyerEmail,
                            };
                            context.setInvoice(newInvoice);
                        }
                    }}
                    onInputChange={(event: React.SyntheticEvent, value: string, reason: string) => {
                        if (reason === 'input' && (value + '').trim()) {
                            const newValue = (value + '').trim();
                            const newInvoice = { ...context.invoice, buyerName: newValue };
                            context.setInvoice(newInvoice);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label={t('invoicing.buyer.label')} error={context.errors.includes('buyerName')} />
                    )}
                />
                <FormHelperText>{t('invoicing.buyer.hint')}</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <TextField
                    label={t('invoicing.streetAndHousenumber.label')}
                    sx={{ m: 1, width: '25ch' }}
                    value={context.invoice.buyerAddress?.streetAndHousenumber}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newInvoice = {
                            ...context.invoice,
                            buyerAddress: { ...(context.invoice.buyerAddress ?? ({} as Address)), streetAndHousenumber: e.target.value },
                        };
                        context.setInvoice(newInvoice);
                    }}
                />
                <FormHelperText>{t('invoicing.streetAndHousenumber.hint')}</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <TextField
                    label={t('invoicing.postalcode.label')}
                    sx={{ m: 1, width: '25ch' }}
                    value={context.invoice.buyerAddress?.postalcode}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newInvoice = {
                            ...context.invoice,
                            buyerAddress: { ...(context.invoice.buyerAddress ?? ({} as Address)), postalcode: e.target.value },
                        };
                        context.setInvoice(newInvoice);
                    }}
                />
                <FormHelperText>{t('invoicing.postalcode.hint')}</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <TextField
                    label={t('invoicing.place.label')}
                    sx={{ m: 1, width: '25ch' }}
                    value={context.invoice.buyerAddress?.place}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newInvoice = {
                            ...context.invoice,
                            buyerAddress: { ...(context.invoice.buyerAddress ?? ({} as Address)), place: e.target.value },
                        };
                        context.setInvoice(newInvoice);
                    }}
                />
                <FormHelperText>{t('invoicing.place.hint')}</FormHelperText>
            </FormControl>
            <CountrySelect
                i18nPrefix="buyer."
                country={context.invoice?.buyerAddress?.country}
                setCountry={(newCountry) => {
                    const newInvoice = {
                        ...context.invoice,
                        buyerAddress: { ...(context.invoice.buyerAddress ?? ({} as Address)), country: newCountry },
                    };
                    context.setInvoice(newInvoice);
                }}
            ></CountrySelect>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <TextField
                    label={t('invoicing.email.label')}
                    error={context.errors.includes('buyerEmail')}
                    sx={{ m: 1, width: '25ch' }}
                    value={context.invoice.buyerEmail}
                    type="email"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newInvoice = { ...context.invoice, buyerEmail: e.target.value };
                        context.setInvoice(newInvoice);
                    }}
                />
                <FormHelperText>{t('invoicing.email.hint')}</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 2, minWidth: 120 }}>
                <Autocomplete
                    sx={{ width: '10rem' }}
                    id="currency"
                    freeSolo
                    options={currencyCodes.codes()}
                    getOptionLabel={(o) => (!!getCurrencySign(o) ? getCurrencySign(o) + ' (' + o + ')' : o)}
                    value={context.invoice.currencySign}
                    onChange={(event: React.SyntheticEvent, newValue: string | null) => {
                        const newInvoice = { ...context.invoice, currency: newValue, currencySign: getCurrencySign(newValue) ?? newValue };
                        context.setInvoice(newInvoice);
                    }}
                    onInputChange={(event: React.SyntheticEvent, value: string, reason: string) => {
                        if (reason === 'input' && (value + '').trim()) {
                            const newValue = (value + '').trim();
                            const newInvoice = {
                                ...context.invoice,
                                currency: newValue,
                                currencySign: getCurrencySign(newValue) ?? newValue,
                            };
                            context.setInvoice(newInvoice);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label={t('invoicing.currency.label')} error={context.errors.includes('currency')} />
                    )}
                />
            </FormControl>
        </Box>
    );
}
