import { FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import countries from 'i18n-iso-countries';
import cs from 'i18n-iso-countries/langs/cs.json';
import da from 'i18n-iso-countries/langs/da.json';
import de from 'i18n-iso-countries/langs/de.json';
import el from 'i18n-iso-countries/langs/el.json';
import en from 'i18n-iso-countries/langs/en.json';
import es from 'i18n-iso-countries/langs/es.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import ga from 'i18n-iso-countries/langs/ga.json';
import iw from 'i18n-iso-countries/langs/he.json';
import hi from 'i18n-iso-countries/langs/hi.json';
import hu from 'i18n-iso-countries/langs/hu.json';
import ja from 'i18n-iso-countries/langs/ja.json';
import ko from 'i18n-iso-countries/langs/ko.json';
import nl from 'i18n-iso-countries/langs/nl.json';
import no from 'i18n-iso-countries/langs/no.json';
import pl from 'i18n-iso-countries/langs/pl.json';
import ru from 'i18n-iso-countries/langs/ru.json';
import sv from 'i18n-iso-countries/langs/sv.json';
import tr from 'i18n-iso-countries/langs/tr.json';
import uk from 'i18n-iso-countries/langs/uk.json';
import vi from 'i18n-iso-countries/langs/vi.json';
import zh from 'i18n-iso-countries/langs/zh.json';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags';

countries.registerLocale(de);
countries.registerLocale(en);
countries.registerLocale(fr);
countries.registerLocale(es);
countries.registerLocale(zh);
countries.registerLocale(pl);
countries.registerLocale(iw);
countries.registerLocale(hi);
countries.registerLocale(el);
countries.registerLocale(da);
countries.registerLocale(ga);
countries.registerLocale(ja);
countries.registerLocale(ko);
countries.registerLocale(nl);
countries.registerLocale(no);
countries.registerLocale(ru);
countries.registerLocale(sv);
countries.registerLocale(cs);
countries.registerLocale(tr);
countries.registerLocale(uk);
countries.registerLocale(hu);
countries.registerLocale(vi);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

type ComponentProps = {
    i18nPrefix: string;
    country: string;
    setCountry: (newCountry: string) => void;
};
export function CountrySelect({ i18nPrefix, country, setCountry }: ComponentProps) {
    const { t, i18n } = useTranslation();

    const lng = i18n.language.substring(0, 2);

    const { namesToCode, sortedNames, codeToNames } = useMemo(() => {
        const codeToNames = countries.getNames(lng, { select: 'official' });
        const namesToCode = {};
        Object.keys(codeToNames).map((code) => {
            const name = codeToNames[code];
            namesToCode[name] = code.toLowerCase();
        });
        const sortedNames = Object.keys(namesToCode).sort(new Intl.Collator(lng).compare);
        return { namesToCode, sortedNames, codeToNames };
    }, [lng]);

    const getFlagCode = (l: string) => {
        switch (l) {
            case 'en':
                return 'gb';
            default:
                return l;
        }
    };

    return (
        <FormControl sx={{ m: 2, width: 200 }}>
            <InputLabel id="select-label">{t(i18nPrefix + 'country')}</InputLabel>
            <Select
                labelId="select-label"
                value={country ?? ''}
                onChange={(event: SelectChangeEvent<string>) => setCountry(event.target.value)}
                input={<OutlinedInput label={t(i18nPrefix + 'country')} />}
                renderValue={(selected) => (
                    <span>
                        <Flag
                            code={getFlagCode(selected)}
                            style={{
                                height: '1rem',
                                width: '2rem',
                                paddingRight: '1rem',
                                verticalAlign: 'middle',
                            }}
                        />
                        {codeToNames[selected.toUpperCase()]}
                    </span>
                )}
                MenuProps={MenuProps}
            >
                {sortedNames.map((n) => (
                    <MenuItem key={namesToCode[n]} value={namesToCode[n]}>
                        <Flag
                            code={getFlagCode(namesToCode[n])}
                            style={{
                                height: '1rem',
                                width: '2rem',
                                paddingRight: '1rem',
                                verticalAlign: 'middle',
                            }}
                        />
                        <ListItemText primary={n} />
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{t(i18nPrefix + 'country.hint')}</FormHelperText>
        </FormControl>
    );
}
