import EventEmitter from 'events';

import { AppBar, Toolbar as MuiToolbar } from '@mui/material';
import { MutableRefObject, ReactElement } from 'react';

import { Menu } from './Menu';

type ComponentProps = {
    context: 'tracking' | 'invoicing' | 'reporting';
    bottomBarRef: MutableRefObject<HTMLElement>;
    appAction: EventEmitter;
    modeBar?: ReactElement;
    appBar?: ReactElement;
};

export function BottomToolbar({ context, bottomBarRef, appAction, modeBar, appBar }: ComponentProps) {
    return (
        <AppBar
            ref={bottomBarRef}
            position="static"
            color="inherit"
            sx={{ position: 'fixed', bottom: 0, zIndex: 1, flexDirection: 'row', whiteSpace: 'nowrap' }}
        >
            <MuiToolbar variant="dense" sx={{ overflowX: 'auto', padding: '8px 0' }}>
                <Menu context={context} appAction={appAction} modeBar={modeBar} />
                {appBar ?? <></>}
            </MuiToolbar>
        </AppBar>
    );
}
