import RestoreIcon from '@mui/icons-material/Restore';
import { Box, Button, FormControl, FormHelperText, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { get } from '../utils/storage-impl';

import { InvoiceContext } from './Invoicing';
import { RichTextEditor } from './RichTextEditor';

import './Layout.css';

const logoPlaceholder =
    'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhLS0gQ3JlYXRlZCB3aXRoIElua3NjYXBlIChodHRwOi8vd3d3Lmlua3NjYXBlLm9yZy8pIC0tPgoKPHN2ZwogICB3aWR0aD0iMjEuNjU3NzQ1bW0iCiAgIGhlaWdodD0iMjEuNjU3NzQ1bW0iCiAgIHZpZXdCb3g9IjAgMCAyMS42NTc3NDUgMjEuNjU3NzQ1IgogICB2ZXJzaW9uPSIxLjEiCiAgIGlkPSJzdmc1IgogICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxkZWZzCiAgICAgaWQ9ImRlZnMyIiAvPgogIDxnCiAgICAgaWQ9ImxheWVyMSIKICAgICB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDAuMzA1MiwtMzcuODE2NDA1KSI+CiAgICA8cmVjdAogICAgICAgc3R5bGU9Im9wYWNpdHk6MC42NzY1NjY7ZmlsbDpub25lO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTojZmYwMDAwO3N0cm9rZS13aWR0aDowLjUwMztzdHJva2UtZGFzaGFycmF5Om5vbmU7c3Ryb2tlLW9wYWNpdHk6MSIKICAgICAgIGlkPSJyZWN0MjM0IgogICAgICAgd2lkdGg9IjIxLjE1NDc0NyIKICAgICAgIGhlaWdodD0iMjEuMTU0NzQ3IgogICAgICAgeD0iNDAuNTU2Njk4IgogICAgICAgeT0iMzguMDY3OTA1IiAvPgogIDwvZz4KPC9zdmc+Cg==';

export function Layout() {
    const { t } = useTranslation();
    const messageBar = useSnackbar();

    const context = React.useContext(InvoiceContext);

    const importLogoRef = useRef<HTMLInputElement>(null);
    const triggerImport = () => importLogoRef.current && importLogoRef.current.click();
    const onImportLogo = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        if (!target.files) {
            return;
        }
        const fileReader = new FileReader();
        const file = target.files[0];
        fileReader.readAsDataURL(file);
        fileReader.onload = async (e) => {
            if (!e.target) {
                return;
            }
            const buffer = e.target.result as string;
            try {
                let asset;
                switch (file.type) {
                    case 'image/png':
                    case 'image/jpeg':
                    case 'image/gif':
                    case 'image/svg+xml':
                        asset = buffer;
                        break;
                    default:
                        throw new Error('unsupported mime type ' + file.type);
                }
                const newInvoice = { ...context.invoice, assets: [asset] };
                context.setInvoice(newInvoice);
                messageBar.enqueueSnackbar(t('success') + ': ' + t('import.svg'), { variant: 'success' });
            } catch (ex) {
                messageBar.enqueueSnackbar(t('failed') + ': ' + t('import.svg') + ' (' + ex + ')', { variant: 'warning' });
            }
            importLogoRef.current.value = null;
        };
    };

    return (
        <Box id="invoice-layout" sx={{ display: 'flex', marginBottom: 8 }}>
            <FormControl sx={{ m: 1, minWidth: 400 }}>
                <RichTextEditor
                    label={t('invoicing.main.label')}
                    value={context.invoice.main}
                    setValue={(newValue) => {
                        const newInvoice = { ...context.invoice, main: newValue };
                        context.setInvoice(newInvoice);
                    }}
                />
                <FormHelperText sx={{ height: '3rem' }}>
                    {t('invoicing.main.hint')}{' '}
                    <Tooltip arrow title={t('reset')}>
                        <IconButton
                            aria-label={t('reset')}
                            onClick={() => context.setInvoice({ ...context.invoice, main: get('default_invoice_main') })}
                            edge="end"
                        >
                            <RestoreIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                </FormHelperText>
            </FormControl>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <input ref={importLogoRef} type="file" accept=".svg,.png,.jpg,.jpeg,.gif" hidden onChange={onImportLogo} />
                    <Button onClick={triggerImport}>{t('invoicing.import.logo')}</Button>
                    <img src={context.invoice.assets?.length ? context.invoice.assets[0] : logoPlaceholder} height="108" />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                    <RichTextEditor
                        label={t('invoicing.footer.label')}
                        value={context.invoice.footer}
                        setValue={(newValue) => {
                            const newInvoice = { ...context.invoice, footer: newValue };
                            context.setInvoice(newInvoice);
                        }}
                    />
                    <FormHelperText sx={{ height: '3rem' }}>
                        {t('invoicing.footer.hint')}{' '}
                        <Tooltip arrow title={t('reset')}>
                            <IconButton
                                aria-label={t('reset')}
                                onClick={() => context.setInvoice({ ...context.invoice, footer: get('default_invoice_footer') })}
                                edge="end"
                            >
                                <RestoreIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                    </FormHelperText>
                </FormControl>
            </Box>
        </Box>
    );
}
