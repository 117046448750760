import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListSubheader, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { endOfDay, endOfMonth, formatISO, isAfter, isDate, isValid, parseISO, startOfMonth, startOfToday, subMonths } from 'date-fns';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Group } from '../reporting/group';
import { Quantisize } from '../reporting/quantisize';
import { getEntries } from '../utils/reporting-utils';
import { getQuantisized } from '../utils/timeline-utils';

import { Invoice } from './Invoice';
import { InvoiceContext } from './Invoicing';
import { Position } from './Position';
import { RichTextEditor } from './RichTextEditor';

function calcPositions(invoice: Invoice): Position[] {
    if (isAfter(parseISO(invoice.invoiceFrom), parseISO(invoice.invoiceTo))) {
        return [];
    }
    const entries = getEntries(
        parseISO(invoice.invoiceFrom),
        endOfDay(parseISO(invoice.invoiceTo)),
        Date.now(),
        Group.OVERALL,
        false,
        false
    );
    return entries.map(
        (entry) =>
            ({
                name: entry.name,
                amount: getQuantisized(Quantisize.FIFTEEN_MINUTES, entry.time) / 3600000,
                netto: invoice.nettoDefault,
                taxRate: invoice.taxRateDefault,
            }) as Position
    );
}

export function PositionsEditor() {
    const { t } = useTranslation();
    const params = useParams();
    const context = React.useContext(InvoiceContext);
    const today = startOfToday();
    const initFrom = startOfMonth(subMonths(today, 1));
    const initTo = endOfMonth(subMonths(today, 1));

    const [previousFrom, setPreviousFrom] = useState('');
    const [previousTo, setPreviousTo] = useState('  ');

    useMemo(() => {
        let newInvoice;
        if (
            params.from &&
            isDate(parseISO(params.from)) &&
            isValid(parseISO(params.from)) &&
            context.invoice.invoiceFrom !== formatISO(parseISO(params.from)).substring(0, 10)
        ) {
            if (!newInvoice) {
                newInvoice = { ...context.invoice };
            }
            newInvoice.invoiceFrom = formatISO(parseISO(params.from)).substring(0, 10);
        }
        if (!context.invoice.invoiceFrom) {
            if (!newInvoice) {
                newInvoice = { ...context.invoice };
            }
            newInvoice.invoiceFrom = formatISO(initFrom).substring(0, 10);
        }
        if (
            params.to &&
            isDate(parseISO(params.to)) &&
            isValid(parseISO(params.to)) &&
            context.invoice.invoiceTo !== formatISO(parseISO(params.to)).substring(0, 10)
        ) {
            if (!newInvoice) {
                newInvoice = { ...context.invoice };
            }
            newInvoice.invoiceTo = formatISO(parseISO(params.to)).substring(0, 10);
        }
        if (!context.invoice.invoiceTo) {
            if (!newInvoice) {
                newInvoice = { ...context.invoice };
            }
            newInvoice.invoiceTo = formatISO(initTo).substring(0, 10);
        }
        if (previousFrom !== context.invoice.invoiceFrom || previousTo !== context.invoice.invoiceTo) {
            setPreviousFrom(context.invoice.invoiceFrom);
            setPreviousTo(context.invoice.invoiceTo);
            if (!newInvoice) {
                newInvoice = { ...context.invoice };
            }
        }
        if (newInvoice) {
            newInvoice.positions = calcPositions(newInvoice);
            if (newInvoice.positions.length) {
                context.setInvoice(newInvoice);
            }
        }
    }, [context, params, initFrom, initTo, previousFrom, previousTo, setPreviousFrom, setPreviousTo]);

    const addPosition = useCallback(() => {
        const n = [...(context.invoice.positions ?? [])];
        n.push({
            name: '',
            amount: 1,
            netto: context.invoice.nettoDefault,
            taxRate: context.invoice.taxRateDefault,
        });
        const newInvoice = { ...context.invoice, positions: n };
        context.setInvoice(newInvoice);
    }, [context]);

    const removePosition = useCallback(
        (pos: number) => {
            const n = [...(context.invoice.positions ?? [])];
            n.splice(pos, 1);
            const newInvoice = { ...context.invoice, positions: n };
            context.setInvoice(newInvoice);
        },
        [context]
    );

    return (
        <Box sx={{ paddingTop: '1rem', marginBottom: 8 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <DatePicker
                    label={t('invoicing.from')}
                    format={t('date.format')}
                    value={context.invoice.invoiceFrom ? parseISO(context.invoice.invoiceFrom) : initFrom}
                    maxDate={context.invoice.invoiceTo ? parseISO(context.invoice.invoiceTo) : new Date()}
                    onChange={(newValue) => {
                        const newInvoice = { ...context.invoice, invoiceFrom: formatISO(newValue).substring(0, 10) };
                        context.setInvoice(newInvoice);
                    }}
                    sx={{ minWidth: '5rem', marginRight: '1rem' }}
                />
                <DatePicker
                    label={t('invoicing.to')}
                    format={t('date.format')}
                    value={context.invoice.invoiceTo ? parseISO(context.invoice.invoiceTo) : initTo}
                    maxDate={today}
                    onChange={(newValue) => {
                        const newInvoice = { ...context.invoice, invoiceTo: formatISO(newValue).substring(0, 10) };
                        context.setInvoice(newInvoice);
                    }}
                    sx={{ minWidth: '5rem', marginRight: '1rem' }}
                />
                <DatePicker
                    label={t('invoicing.at')}
                    format={t('date.format')}
                    value={context.invoice.invoiceDate ? new Date(context.invoice.invoiceDate) : today}
                    maxDate={today}
                    onChange={(newValue) => {
                        const newInvoice = { ...context.invoice, invoiceDate: formatISO(newValue).substring(0, 10) };
                        context.setInvoice(newInvoice);
                    }}
                    sx={{ minWidth: '5rem', marginRight: '1rem', marginLeft: 'auto' }}
                />
            </Box>
            <RichTextEditor
                label={t('invoicing.details')}
                value={context.invoice.details}
                setValue={(v) => {
                    const newInvoice = { ...context.invoice, details: v };
                    context.setInvoice(newInvoice);
                }}
            />
            <List
                subheader={
                    <ListSubheader sx={{ pr: 0, zIndex: 2 }}>
                        <Typography sx={{ display: 'inline-flex' }}>{t('invoicing.positions')}</Typography>
                        <Stack direction="row" sx={{ float: 'right', marginRight: '1rem' }}>
                            <IconButton edge="end" aria-label="add" onClick={() => addPosition()}>
                                <AddCircleIcon />
                            </IconButton>
                        </Stack>
                    </ListSubheader>
                }
            >
                {(context.invoice.positions ?? []).map((position, index) => (
                    <ListItem key={index} sx={{ alignItems: 'stretch' }}>
                        <Stack direction="column" sx={{ width: '100%', margin: '0' }}>
                            <Stack direction="row" sx={{ width: '100%' }}>
                                <TextField
                                    label={t('invoicing.position.name')}
                                    sx={{ margin: '0', flex: 1, minWidth: '6rem' }}
                                    value={position.name}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        const n = [...context.invoice.positions];
                                        n[index] = { ...context.invoice.positions[index] };
                                        n[index].name = e.target.value;
                                        const newInvoice = { ...context.invoice, positions: n };
                                        context.setInvoice(newInvoice);
                                    }}
                                />
                                <TextField
                                    label={t('invoicing.position.amount')}
                                    error={context.errors.includes('positions') && !position.amount}
                                    type="number"
                                    sx={{ margin: '0 0 0 1rem', minWidth: '3rem' }}
                                    inputProps={{ min: 0 }}
                                    value={position.amount}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        const n = [...context.invoice.positions];
                                        n[index] = { ...context.invoice.positions[index] };
                                        n[index].amount = Math.max(+e.target.value, 0);
                                        const newInvoice = { ...context.invoice, positions: n };
                                        context.setInvoice(newInvoice);
                                    }}
                                />
                                <TextField
                                    label={t('invoicing.position.netto')}
                                    error={context.errors.includes('positions') && !position.netto}
                                    type="number"
                                    sx={{ margin: '0 0 0 1rem', minWidth: '4rem' }}
                                    inputProps={{ min: 0 }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{context.invoice.currencySign}</InputAdornment>,
                                    }}
                                    value={position.netto}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        const n = [...context.invoice.positions];
                                        n[index] = { ...context.invoice.positions[index] };
                                        n[index].netto = Math.max(+e.target.value, 0);
                                        const newInvoice = { ...context.invoice, positions: n };
                                        context.setInvoice(newInvoice);
                                    }}
                                />
                                {context.invoice.taxRateDefault > 0 && (
                                    <TextField
                                        label={t('invoicing.position.taxRate')}
                                        type="number"
                                        sx={{ margin: '0 0 0 1rem', minWidth: '4rem' }}
                                        inputProps={{ min: 0 }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{'%'}</InputAdornment>,
                                        }}
                                        value={position.taxRate}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            const n = [...context.invoice.positions];
                                            n[index] = { ...context.invoice.positions[index] };
                                            n[index].taxRate = Math.max(+e.target.value, 0);
                                            const newInvoice = { ...context.invoice, positions: n };
                                            context.setInvoice(newInvoice);
                                        }}
                                    />
                                )}
                            </Stack>
                        </Stack>
                        <ListItemIcon sx={{ minWidth: 0 }}>
                            <IconButton
                                sx={{
                                    height: '2.5rem',
                                    marginTop: 'auto',
                                    marginBottom: '1.5rem',
                                }}
                                edge="end"
                                aria-label="delete"
                                onClick={() => removePosition(index)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </ListItemIcon>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}
