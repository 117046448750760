import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { CountrySelect } from './CountrySelect';
import { Address, Due } from './Invoice';
import { InvoiceContext } from './Invoicing';

export function Seller() {
    const { t } = useTranslation();

    const context = React.useContext(InvoiceContext);

    return (
        <Box sx={{ marginBottom: 8 }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <TextField
                    label={t('invoicing.seller.label')}
                    error={context.errors.includes('sellerName')}
                    sx={{ m: 1, width: '25ch' }}
                    value={context.invoice.sellerName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newInvoice = { ...context.invoice, sellerName: e.target.value };
                        context.setInvoice(newInvoice);
                    }}
                />
                <FormHelperText>{t('invoicing.seller.hint')}</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <TextField
                    label={t('invoicing.streetAndHousenumber.label')}
                    sx={{ m: 1, width: '25ch' }}
                    value={context.invoice.sellerAddress?.streetAndHousenumber}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newInvoice = {
                            ...context.invoice,
                            sellerAddress: { ...(context.invoice.sellerAddress ?? ({} as Address)), streetAndHousenumber: e.target.value },
                        };
                        context.setInvoice(newInvoice);
                    }}
                />
                <FormHelperText>{t('invoicing.streetAndHousenumber.hint')}</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <TextField
                    label={t('invoicing.postalcode.label')}
                    sx={{ m: 1, width: '25ch' }}
                    value={context.invoice.sellerAddress?.postalcode}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newInvoice = {
                            ...context.invoice,
                            sellerAddress: { ...(context.invoice.sellerAddress ?? ({} as Address)), postalcode: e.target.value },
                        };
                        context.setInvoice(newInvoice);
                    }}
                />
                <FormHelperText>{t('invoicing.postalcode.hint')}</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <TextField
                    label={t('invoicing.place.label')}
                    sx={{ m: 1, width: '25ch' }}
                    value={context.invoice.sellerAddress?.place}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newInvoice = {
                            ...context.invoice,
                            sellerAddress: { ...(context.invoice.sellerAddress ?? ({} as Address)), place: e.target.value },
                        };
                        context.setInvoice(newInvoice);
                    }}
                />
                <FormHelperText>{t('invoicing.place.hint')}</FormHelperText>
            </FormControl>
            <CountrySelect
                i18nPrefix="seller."
                country={context.invoice?.sellerAddress?.country}
                setCountry={(newCountry) => {
                    const newInvoice = {
                        ...context.invoice,
                        sellerAddress: { ...(context.invoice.sellerAddress ?? ({} as Address)), country: newCountry },
                    };
                    context.setInvoice(newInvoice);
                }}
            ></CountrySelect>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <TextField
                    label={t('invoicing.email.label')}
                    error={context.errors.includes('sellerEmail')}
                    sx={{ m: 1, width: '25ch' }}
                    value={context.invoice.sellerEmail}
                    type="email"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newInvoice = { ...context.invoice, sellerEmail: e.target.value };
                        context.setInvoice(newInvoice);
                    }}
                />
                <FormHelperText>{t('invoicing.email.hint')}</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <TextField
                    label={t('invoicing.telephone.label')}
                    sx={{ m: 1, width: '25ch' }}
                    value={context.invoice.sellerTelephone}
                    type="tel"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newInvoice = { ...context.invoice, sellerTelephone: e.target.value };
                        context.setInvoice(newInvoice);
                    }}
                />
                <FormHelperText>{t('invoicing.telephone.hint')}</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <TextField
                    label={t('invoicing.vatId.label')}
                    error={context.errors.includes('vatId')}
                    sx={{ m: 1, width: '25ch' }}
                    value={context.invoice.vatId}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newInvoice = { ...context.invoice, vatId: e.target.value };
                        context.setInvoice(newInvoice);
                    }}
                />
                <FormHelperText>{t('invoicing.vatId.hint')}</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <TextField
                    label={t('invoicing.nettoDefault.label')}
                    sx={{ m: 1, width: '25ch' }}
                    type="number"
                    inputProps={{ min: 0 }}
                    value={context.invoice.nettoDefault}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newInvoice = { ...context.invoice, nettoDefault: +e.target.value };
                        context.setInvoice(newInvoice);
                    }}
                />
                <FormHelperText>{t('invoicing.nettoDefault.hint')}</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <TextField
                    label={t('invoicing.taxRateDefault.label')}
                    sx={{ m: 1, width: '25ch' }}
                    value={context.invoice.taxRateDefault}
                    type="number"
                    inputProps={{ min: 0 }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newInvoice = { ...context.invoice, taxRateDefault: +e.target.value };
                        context.setInvoice(newInvoice);
                    }}
                />
                <FormHelperText>{t('invoicing.taxRateDefault.hint')}</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 220 }}>
                <TextField
                    label={t('invoicing.iban.label')}
                    error={context.errors.includes('iban')}
                    sx={{ m: 1 }}
                    value={context.invoice.iban}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newInvoice = { ...context.invoice, iban: e.target.value };
                        context.setInvoice(newInvoice);
                    }}
                />
                <FormHelperText>{t('invoicing.iban.hint')}</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <TextField
                    label={t('invoicing.bic.label')}
                    error={context.errors.includes('bic')}
                    sx={{ m: 1, width: '25ch' }}
                    value={context.invoice.bic}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newInvoice = { ...context.invoice, bic: e.target.value };
                        context.setInvoice(newInvoice);
                    }}
                />
                <FormHelperText>{t('invoicing.bic.hint')}</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 2, minWidth: 120 }}>
                <InputLabel id="group-label">{t('invoicing.due.label')}</InputLabel>
                <Select
                    data-testid="report.group"
                    labelId="group-label"
                    id="group"
                    value={context.invoice.due ?? Due.FOUR_WEEKS}
                    label={t('invoicing.due.label')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newInvoice = { ...context.invoice, due: e.target.value };
                        context.setInvoice(newInvoice);
                    }}
                >
                    <MenuItem value={Due.TEN_DAYS}>{t('invoicing.due.tenDays')}</MenuItem>
                    <MenuItem value={Due.TWO_WEEKS}>{t('invoicing.due.twoWeeks')}</MenuItem>
                    <MenuItem value={Due.TWENTY_DAYS}>{t('invoicing.due.twentyDays')}</MenuItem>
                    <MenuItem value={Due.THREE_WEEKS}>{t('invoicing.due.threeWeeks')}</MenuItem>
                    <MenuItem value={Due.FOUR_WEEKS}>{t('invoicing.due.fourWeeks')}</MenuItem>
                    <MenuItem value={Due.THIRTY_DAYS}>{t('invoicing.due.thirtyDays')}</MenuItem>
                    <MenuItem value={Due.FIVE_WEEKS}>{t('invoicing.due.fiveWeeks')}</MenuItem>
                    <MenuItem value={Due.SIX_WEEKS}>{t('invoicing.due.sixWeeks')}</MenuItem>
                </Select>
                <FormHelperText>{t('invoicing.due.hint')}</FormHelperText>
            </FormControl>
        </Box>
    );
}
