import { Position } from './Position';

export interface Invoice {
    invoiceNumber: string;
    invoiceDate: string;
    invoiceFrom: string;
    invoiceTo: string;
    oration: string;
    buyerName: string;
    buyerAddress: Address;
    buyerEmail: string;
    sellerName: string;
    sellerAddress: Address;
    sellerEmail: string;
    sellerTelephone: string;
    vatId: string;
    taxRateDefault: number;
    nettoDefault: number;
    due: string;
    currency: string;
    currencySign: string;
    iban: string;
    bic: string;
    details: string;
    positions: Position[];
    assets: string[];
    main: string;
    footer: string;
    filename: string;
    lang: string;
    lastGenerated: number;
}

export interface Address {
    streetAndHousenumber: string;
    postalcode: string;
    place: string;
    country: string;
}

export enum Due {
    TEN_DAYS = '+ 10 days',
    TWO_WEEKS = '+ 2 weeks',
    TWENTY_DAYS = '+ 20 days',
    THREE_WEEKS = '+ 3 weeks',
    FOUR_WEEKS = '+ 4 weeks',
    THIRTY_DAYS = '+ 30 days',
    FIVE_WEEKS = '+ 5 weeks',
    SIX_WEEKS = '+ 6 weeks',
}
