import EventEmitter from 'events';

import LogoutIcon from '@mui/icons-material/Logout';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { Box, Button, Tooltip, useTheme } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Sync } from './connect/sync';
import { supportedLngs } from './i18n';
import { resetInvoiceDefaults } from './invoice/invoice-utils';
import { Event } from './timeline/event';
import { TimelineComponent } from './timeline/Timeline.component';
import { useTimeline } from './timeline/use-timeline.hook';
import { BottomToolbar } from './toolbar/BottomToolbar';
import { ModeBar } from './toolbar/ModeBar';
import { Cell } from './tracking/cell';
import { CellMode } from './tracking/cell-mode';
import { Info } from './tracking/Info.component';
import { TrackingComponent } from './tracking/Tracking.component';
import {
    ACTION_CHANGE_EVENT,
    ACTION_CHANGE_LAYOUT,
    ACTION_NAVIGATE,
    ACTION_REFRESH,
    ACTION_RELOAD_SYNC,
    ACTION_UPDATE_EVENTS,
    ACTION_UPDATE_LAYOUT,
    ACTION_USER_CHANGE_EVENT,
    ACTION_USER_CHANGE_LAYOUT,
} from './utils/action.keys';
import { addEvent, getEventAt, getEvents, moveCursorToNext, moveCursorToPrevious } from './utils/event-utils';
import { initialLayout } from './utils/layout-utils';
import { KEY_CONNECTIONS, KEY_LAYOUT, KEY_LAYOUT_CHANGED } from './utils/locale.storage.keys';
import { get } from './utils/storage-impl';
import { useConfig } from './utils/use-config';

type ComponentProps = {
    appAction: EventEmitter;
};

const initSync = (JSON.parse(get(KEY_CONNECTIONS)) ?? { pairings: {}, version: 0 }) as Sync;

export function Dashboard({ appAction }: ComponentProps) {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const params = useParams();
    const lang = params.lang;
    if (lang && lang !== i18n.language && supportedLngs.includes(lang)) {
        resetInvoiceDefaults();
        i18n.changeLanguage(lang);
    }

    const bottomBarRef = useRef<HTMLElement>(null);
    const [layout, setLayout] = useConfig<string>(KEY_LAYOUT, initialLayout(t));
    const [, setLayoutChanged] = useConfig<number>(KEY_LAYOUT_CHANGED, -1);
    const [mode, setModeInternal] = useState<CellMode>(CellMode.JUMP);
    const { cursor, focus, saveNow, zoom, updateNow, setCursor, setZoom } = useTimeline();
    const [enterInfo, setEnterInfo] = useState(false);
    const [cursorEvent, setCursorEvent] = useState<Event>();
    const [sync, setSync] = useState<Sync>(initSync);
    const [info, setInfo] = useState('');
    const setMode = useCallback(
        (newMode: CellMode) => {
            if (newMode === CellMode.OFF) {
                if (cursorEvent && cursorEvent.n) {
                    appAction.emit(ACTION_USER_CHANGE_EVENT, { ...cursorEvent, e: cursor });
                    setCursorEvent(undefined);
                    document.title = t('tracking') + ' - ' + t('app.title');
                    document.querySelector('meta[name="description"]').setAttribute('content', t('app.description'));
                    updateNow();
                }
                setModeInternal(CellMode.JUMP);
            } else {
                setModeInternal(newMode);
            }
        },
        [appAction, setModeInternal, cursor, cursorEvent, setCursorEvent, updateNow, t]
    );
    const navigate = useNavigate();

    const onCursorChanged = useCallback(
        (time: number) => {
            const eventsAtTime = getEvents(time, time);
            const events = eventsAtTime.filter((e) => e.s <= time && e.n && (!e.e || e.e > time));
            if (events.length) {
                const event = events[events.length - 1];
                setCursorEvent(event);
                document.title = event.n + ' - ' + t('tracking') + ' - ' + t('app.title');
                document.querySelector('meta[name="description"]').setAttribute('content', t('app.description'));
            } else {
                setCursorEvent(undefined);
                document.title = t('tracking') + ' - ' + t('app.title');
                document.querySelector('meta[name="description"]').setAttribute('content', t('app.description'));
            }
            setCursor(time);
            const info = eventsAtTime.filter((e) => e.s === time && !!e.i);
            if (info.length) {
                setInfo(info[0].i as string);
            } else {
                setInfo('');
            }
        },
        [setCursor, setCursorEvent, setInfo, t]
    );

    useEffect(() => {
        const refresh = () => {
            setLayout(get(KEY_LAYOUT) ?? initialLayout(t));
            updateNow();
        };
        const changeEvent = (event: Event) => {
            if (!event.n && !event.i) {
                setMode(CellMode.OFF);
            } else {
                addEvent(event);
            }
            const now = updateNow();
            onCursorChanged(now);
        };
        const changeLayout = (l: string) => {
            if (l !== layout) {
                try {
                    Cell.fromJson(l);
                } catch (e) {
                    console.log('layout parsing error', e);
                    return;
                }
                setLayout(l);
            }
        };
        const doNavigate = (to: string) => {
            navigate(to);
        };
        const updateEvents = () => updateNow();
        const updateLayout = () => appAction.emit(ACTION_REFRESH);
        const reloadSync = (newSync: Sync) => {
            setSync(newSync);
        };
        appAction.on(ACTION_RELOAD_SYNC, reloadSync);
        appAction.on(ACTION_REFRESH, refresh);
        appAction.on(ACTION_USER_CHANGE_EVENT, changeEvent);
        appAction.on(ACTION_CHANGE_EVENT, changeEvent);
        appAction.on(ACTION_USER_CHANGE_LAYOUT, changeLayout);
        appAction.on(ACTION_CHANGE_LAYOUT, changeLayout);
        appAction.on(ACTION_NAVIGATE, doNavigate);
        appAction.on(ACTION_UPDATE_EVENTS, updateEvents);
        appAction.on(ACTION_UPDATE_LAYOUT, updateLayout);

        return () => {
            appAction.off(ACTION_RELOAD_SYNC, reloadSync);
            appAction.off(ACTION_REFRESH, refresh);
            appAction.off(ACTION_USER_CHANGE_EVENT, changeEvent);
            appAction.off(ACTION_CHANGE_EVENT, changeEvent);
            appAction.off(ACTION_USER_CHANGE_LAYOUT, changeLayout);
            appAction.off(ACTION_CHANGE_LAYOUT, changeLayout);
            appAction.off(ACTION_NAVIGATE, doNavigate);
            appAction.off(ACTION_UPDATE_EVENTS, updateEvents);
            appAction.off(ACTION_UPDATE_LAYOUT, updateLayout);
        };
    }, [appAction, t, updateNow, onCursorChanged, setMode, layout, setLayout, setLayoutChanged, navigate]);

    const handleKeyUp = useCallback(
        (event: KeyboardEvent) => {
            if (event.target['tagName'] === 'INPUT') {
                return;
            }
            switch (event.key) {
                case 'ArrowLeft':
                    moveCursorToPrevious(cursor, saveNow, onCursorChanged);
                    break;
                case 'ArrowRight':
                    moveCursorToNext(cursor, saveNow, onCursorChanged);
                    break;
                case 'ArrowDown':
                    setCursor(saveNow);
                    onCursorChanged(saveNow);
                    break;
            }
        },
        [cursor, saveNow, onCursorChanged, setCursor]
    );

    useEffect(() => {
        document.addEventListener('keyup', handleKeyUp);
        return () => {
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [handleKeyUp]);

    const onTrack = useCallback(
        (name: string, color: string) => {
            if (!cursorEvent || cursorEvent.n !== name || cursorEvent.c !== color) {
                appAction.emit(ACTION_USER_CHANGE_EVENT, { s: cursor === saveNow ? Date.now() : cursor, n: name, c: color });
            }
            updateNow();
        },
        [appAction, cursor, saveNow, updateNow, cursorEvent]
    );

    const onInfo = useCallback(
        (info: string) => {
            appAction.emit(ACTION_USER_CHANGE_EVENT, { s: cursor === saveNow ? Date.now() : cursor, i: info });
            updateNow();
        },
        [appAction, cursor, saveNow, updateNow]
    );

    useMemo(() => {
        const event = getEventAt(cursor);
        if (JSON.stringify(cursorEvent) !== JSON.stringify(event)) {
            setCursorEvent(event);
        }
        if (event) {
            document.title = event.n + ' - ' + t('tracking') + ' - ' + t('app.title');
            document.querySelector('meta[name="description"]').setAttribute('content', t('app.description'));
        } else {
            document.title = t('tracking') + ' - ' + t('app.title');
            document.querySelector('meta[name="description"]').setAttribute('content', t('app.description'));
        }
    }, [cursor, cursorEvent, setCursorEvent, t]);

    const bh = Math.max(bottomBarRef.current?.offsetHeight ?? 77, 77);
    return (
        <Box
            sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                backgroundColor: theme.palette.background.default,
                paddingBottom: bh + 'px',
            }}
        >
            <TimelineComponent
                cursor={cursor}
                focus={focus}
                zoom={zoom}
                setZoom={setZoom}
                saveNow={saveNow}
                onCursorChanged={onCursorChanged}
            />
            <TrackingComponent
                spectatorMode={false}
                mode={mode}
                cursorEvent={cursorEvent}
                onTrack={onTrack}
                onMode={setMode}
                layout={layout}
                onLayout={(layout: string, userTriggered: boolean) =>
                    appAction.emit(userTriggered ? ACTION_USER_CHANGE_LAYOUT : ACTION_CHANGE_LAYOUT, layout)
                }
            />
            <BottomToolbar
                bottomBarRef={bottomBarRef}
                appAction={appAction}
                context="tracking"
                modeBar={
                    <ModeBar
                        mode={mode}
                        setMode={(newMode) => {
                            setMode(newMode);
                        }}
                        sync={sync}
                    />
                }
                appBar={
                    <>
                        <Box sx={{ flexGrow: 1, display: 'inline-flex' }}>
                            {enterInfo ? (
                                <Info info={info} setInfo={setInfo} onInfo={onInfo} onClose={() => setEnterInfo(false)} />
                            ) : (
                                <>
                                    <DateTimePicker
                                        ampm={false}
                                        label={t('time')}
                                        format={t('datetime.format')}
                                        value={new Date(cursor)}
                                        sx={{ height: '100%', margin: 'auto', minWidth: '9rem' }}
                                        onChange={(newValue) => {
                                            if (!newValue) {
                                                return;
                                            }
                                            setCursor(newValue.getTime());
                                        }}
                                    />
                                    <Tooltip arrow title={t('info.enter')}>
                                        <Button
                                            size="large"
                                            aria-label={t('info.enter')}
                                            aria-controls={'primary-search-account-menu-mobile'}
                                            aria-haspopup="true"
                                            data-testid="info.enter"
                                            onClick={() => setEnterInfo(true)}
                                            color="inherit"
                                        >
                                            <TextsmsIcon />
                                            <span className="sm-hide">{t('info.enter')}</span>
                                        </Button>
                                    </Tooltip>
                                </>
                            )}
                        </Box>
                        {!enterInfo && (
                            <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                    <ModeBar mode={mode} setMode={setMode} sync={sync} />
                                </Box>
                                <Tooltip arrow title={t('mode.off')}>
                                    <Button
                                        size="large"
                                        aria-label={t('mode.off')}
                                        aria-controls={'primary-mode.off-mobile'}
                                        data-testid="mode.off"
                                        aria-haspopup="true"
                                        onClick={() => setMode(CellMode.OFF)}
                                        color="inherit"
                                    >
                                        <LogoutIcon />
                                        <span className="sm-hide">{t('mode.off')}</span>
                                    </Button>
                                </Tooltip>
                            </Box>
                        )}
                    </>
                }
            />
        </Box>
    );
}
