import { EventEmitter } from 'stream';

import ClearIcon from '@mui/icons-material/Clear';
import {
    Box,
    Button,
    DialogActions,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    IconButton,
    Modal,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { emphasizeLogo } from './assets/source-data';
import { ConnectionsDialog } from './connect/ConnectionsDialog';
import { loadInvoices } from './invoice/invoice-utils';
import { Event } from './timeline/event';
import { MaintenanceDialog } from './toolbar/MaintenanceDialog';
import { Cell } from './tracking/cell';
import {
    ACTION_CHANGE_EVENT,
    ACTION_IMPORT_EVENTS,
    ACTION_IMPORT_INVOICES,
    ACTION_IMPORT_LAYOUT,
    ACTION_OPEN_CONNECTIONS,
    ACTION_OPEN_DIALOG,
    ACTION_OPEN_IMPRINT,
    ACTION_OPEN_LEGALS,
    ACTION_OPEN_MAINTENANCE,
    ACTION_UPDATE_EVENTS,
    ACTION_UPDATE_INVOICES,
    ACTION_UPDATE_LAYOUT,
    ACTION_USER_CHANGE_EVENT,
    ACTION_USER_CHANGE_LAYOUT,
} from './utils/action.keys';
import { clearAllEvents, importEvents, validateEventsImport } from './utils/event-utils';
import { KEY_LEGALS_SEEN } from './utils/locale.storage.keys';
import { get } from './utils/storage-impl';
import { useConfig } from './utils/use-config';

const REQUIRED_LEGAL_UPDATE = '2024-01-04';

type ComponentProps = {
    appAction: EventEmitter;
};

export function AppModals({ appAction }: ComponentProps) {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const messageBar = useSnackbar();

    const [showLegals, setShowLegals] = useState(false);
    const [legalsSeen, setLegalsSeen] = useConfig(KEY_LEGALS_SEEN, '');
    const [showImprint, setShowImprint] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogAction, setDialogAction] = useState(() => undefined);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogText, setDialogText] = useState('');
    const [dialogOk, setDialogOk] = useState('');
    const [dialogCancel, setDialogCancel] = useState('');
    const [showMaintenance, setShowMaintenance] = useState(false);
    const [showConnections, setShowConnections] = useState(false);

    const importEventsRef = useRef<HTMLInputElement>(null);
    const triggerImportEvents = () => importEventsRef.current && importEventsRef.current.click();
    const importLayoutRef = useRef<HTMLInputElement>(null);
    const triggerImportLayout = () => importLayoutRef.current && importLayoutRef.current.click();
    const importInvoicesRef = useRef<HTMLInputElement>(null);
    const triggerImportInvoices = () => importInvoicesRef.current && importInvoicesRef.current.click();

    const openDialog = useCallback(
        (o: unknown) => {
            setDialogTitle(o['title']);
            setDialogText(o['text']);
            setDialogCancel(o['cancel'] ?? '');
            setDialogOk(o['ok']);
            setDialogAction(() => {
                return o['action'];
            });
            setDialogOpen(true);
        },
        [setDialogTitle, setDialogText, setDialogCancel, setDialogOk, setDialogAction, setDialogOpen]
    );

    useEffect(() => {
        const triggerShowLegals = () => setShowLegals(true);
        appAction.on(ACTION_OPEN_LEGALS, triggerShowLegals);
        const triggerShowImprint = () => setShowImprint(true);
        appAction.on(ACTION_OPEN_IMPRINT, triggerShowImprint);
        appAction.on(ACTION_IMPORT_EVENTS, triggerImportEvents);
        appAction.on(ACTION_IMPORT_LAYOUT, triggerImportLayout);
        appAction.on(ACTION_IMPORT_INVOICES, triggerImportInvoices);
        appAction.on(ACTION_OPEN_DIALOG, openDialog);
        const triggerShowMaintainance = () => setShowMaintenance(true);
        appAction.on(ACTION_OPEN_MAINTENANCE, triggerShowMaintainance);
        const triggerShowConnections = () => setShowConnections(true);
        appAction.on(ACTION_OPEN_CONNECTIONS, triggerShowConnections);
        return () => {
            appAction.off(ACTION_OPEN_LEGALS, triggerShowLegals);
            appAction.off(ACTION_OPEN_IMPRINT, triggerShowImprint);
            appAction.off(ACTION_IMPORT_EVENTS, triggerImportEvents);
            appAction.off(ACTION_IMPORT_LAYOUT, triggerImportLayout);
            appAction.off(ACTION_IMPORT_INVOICES, triggerImportInvoices);
            appAction.off(ACTION_OPEN_DIALOG, openDialog);
            appAction.off(ACTION_OPEN_MAINTENANCE, triggerShowMaintainance);
            appAction.off(ACTION_OPEN_CONNECTIONS, triggerShowConnections);
        };
    }, [appAction, setShowLegals, setShowImprint, openDialog, setShowMaintenance, setShowConnections]);

    const onImportEvents = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        if (!target.files) {
            return;
        }
        const fileReader = new FileReader();
        fileReader.readAsText(target.files[0]);
        fileReader.onload = (e) => {
            if (!e.target) {
                return;
            }
            const events = e.target.result as string;
            try {
                validateEventsImport(events);
                importEvents(events, (event: Event, userTriggered: boolean) =>
                    appAction.emit(userTriggered ? ACTION_USER_CHANGE_EVENT : ACTION_CHANGE_EVENT, event)
                );
                messageBar.enqueueSnackbar(t('success') + ': ' + t('import.csv'), { variant: 'success' });
                appAction.emit(ACTION_UPDATE_EVENTS);
            } catch (ex) {
                messageBar.enqueueSnackbar(t('failed') + ': ' + t('import.csv') + ' (' + ex + ')', { variant: 'warning' });
            }
            importEventsRef.current.value = null;
        };
    };

    const onImportLayout = useCallback(
        ({ target }) => {
            const fileReader = new FileReader();
            fileReader.readAsText(target.files[0]);
            fileReader.onload = (e) => {
                const newLayout = e.target.result as string;
                try {
                    Cell.fromJson(newLayout);
                    appAction.emit(ACTION_USER_CHANGE_LAYOUT, newLayout);
                    messageBar.enqueueSnackbar(t('success') + ': ' + t('import.layout'), { variant: 'success' });
                    appAction.emit(ACTION_UPDATE_LAYOUT);
                } catch (ex) {
                    messageBar.enqueueSnackbar(t('failed') + ': ' + t('import.layout') + ' ' + '(' + ex + ')', { variant: 'warning' });
                }
                importLayoutRef.current.value = null;
            };
        },
        [messageBar, t, appAction]
    );

    const onImportInvoices = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        if (!target.files) {
            return;
        }
        const fileReader = new FileReader();
        fileReader.readAsText(target.files[0]);
        fileReader.onload = (e) => {
            if (!e.target) {
                return;
            }
            try {
                const data = JSON.parse(e.target.result as string);
                loadInvoices(data);
                messageBar.enqueueSnackbar(t('success') + ': ' + t('import.invoices'), { variant: 'success' });
                appAction.emit(ACTION_UPDATE_INVOICES);
            } catch (ex) {
                messageBar.enqueueSnackbar(t('failed') + ': ' + t('import.invoices') + ' (' + ex + ')', { variant: 'warning' });
            }
            importInvoicesRef.current.value = null;
        };
    };

    useEffect(() => {
        let timer = undefined;
        if (!window.location.href.match(/\/view\//) && legalsSeen < REQUIRED_LEGAL_UPDATE) {
            const legalsCheck = () => {
                if (!get(KEY_LEGALS_SEEN) || get(KEY_LEGALS_SEEN) < REQUIRED_LEGAL_UPDATE) {
                    if (dialogOpen || showImprint || showMaintenance || showConnections) {
                        setTimeout(legalsCheck, 5000);
                        return;
                    }
                    setShowLegals(true);
                }
            };
            timer = setTimeout(legalsCheck, 3000);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [legalsSeen, setShowLegals, dialogOpen, showImprint, showMaintenance, showConnections]);

    const clearEvents = useCallback(() => {
        appAction.emit(ACTION_OPEN_DIALOG, {
            title: t('confirm.clear.csv.title'),
            text: t('confirm.clear.csv.text'),
            ok: t('ok'),
            cancel: t('abort'),
            action: (ok: boolean) => {
                if (ok) {
                    clearAllEvents();
                    messageBar.enqueueSnackbar(t('success') + ': ' + t('clear.csv'), { variant: 'success' });
                }
            },
        });
    }, [messageBar, appAction, t]);

    useEffect(() => {
        const showMaintenance = window.location.pathname.endsWith('/export') || window.location.pathname.endsWith('/import');
        if (showMaintenance) {
            appAction.emit(ACTION_OPEN_MAINTENANCE);
        }
        const showSync = location.pathname.endsWith('/connections');
        if (showSync) {
            appAction.emit(ACTION_OPEN_CONNECTIONS);
        }
    }, [appAction]);

    return (
        <>
            <input ref={importEventsRef} type="file" accept="text/csv" hidden onChange={onImportEvents} />
            <input ref={importLayoutRef} type="file" accept="application/json" hidden onChange={onImportLayout} />
            <input ref={importInvoicesRef} type="file" accept=".invoices" hidden onChange={onImportInvoices} />
            <Modal
                open={showLegals}
                onClose={() => {
                    setLegalsSeen(format(new Date(), 'yyyy-MM-dd'));
                    setShowLegals(false);
                }}
                aria-labelledby="legals"
                aria-describedby="legals-description"
            >
                <Box
                    sx={{
                        position: 'absolute' as const,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '80vw', md: 720 },
                        overflowY: 'auto',
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        color: 'primary.main',
                        border: '2px solid #000',
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                    }}
                >
                    <Tooltip arrow title={t('close')}>
                        <IconButton
                            aria-label={t('close')}
                            data-testid="legals.close"
                            onClick={() => {
                                setLegalsSeen(format(new Date(), 'yyyy-MM-dd'));
                                setShowLegals(false);
                            }}
                            edge="end"
                            style={{ margin: '1em 0 0 auto', display: 'block' }}
                        >
                            <ClearIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <h2>{t('legals.title')}</h2>
                    <p>
                        {t('legals.text0')}
                        <a
                            href={'https://www.emphasize.de/' + (i18n.language.substring(0, 2) === 'de' ? 'de' : 'en') + '/api.html'}
                            target="_blank"
                        >
                            {t('legals.api')}
                        </a>
                        {t('legals.text1')}
                    </p>
                    <a
                        href={'https://www.emphasize-it.de/' + (i18n.language.substring(0, 2) === 'de' ? 'de' : 'en') + '/contact.html'}
                        target="_blank"
                        style={{ textAlign: 'right', display: 'block' }}
                    >
                        {t('legals.title')}
                    </a>
                    <Button
                        data-testid="legals.ok"
                        onClick={() => {
                            setLegalsSeen(format(new Date(), 'yyyy-MM-dd'));
                            setShowLegals(false);
                        }}
                        style={{ margin: '1em 0 0 auto', display: 'block' }}
                    >
                        {t('ok')}
                    </Button>
                </Box>
            </Modal>

            <Modal
                open={showImprint}
                onClose={() => setShowImprint(false)}
                aria-labelledby="imprint"
                aria-describedby="imprint-description"
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        setShowImprint(false);
                        event.preventDefault();
                    } else if (event.key === 'Escape') {
                        setShowImprint(false);
                        event.preventDefault();
                    }
                }}
            >
                <Box
                    sx={{
                        position: 'absolute' as const,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '80vw', md: 720 },
                        overflowY: 'auto',
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        color: 'primary.main',
                        border: '2px solid #000',
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                    }}
                >
                    <Tooltip arrow title={t('close')}>
                        <IconButton
                            aria-label={t('close')}
                            onClick={() => setShowImprint(false)}
                            edge="end"
                            style={{ margin: '1em 0 0 auto', display: 'block' }}
                        >
                            <ClearIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <img style={{ margin: '0 auto', display: 'block' }} src={emphasizeLogo}></img>
                    <h2>{t('imprint.title')}</h2>
                    <p>{t('imprint.text')}</p>
                    <a
                        href={'https://www.emphasize-it.de/' + (i18n.language.substring(0, 2) === 'de' ? 'de' : 'en') + '/contact.html'}
                        target="_blank"
                        style={{ textAlign: 'right', display: 'block' }}
                    >
                        {t('imprint.title')}
                    </a>
                    <a href={'https://www.emphasize.de/' + i18n.language.substring(0, 2) + '/'} target="_blank">
                        {t('app.docs')}
                    </a>
                    <Button onClick={() => setShowImprint(false)} style={{ margin: '1em 0 0 auto', display: 'block' }}>
                        {t('ok')}
                    </Button>
                </Box>
            </Modal>

            <Dialog
                fullScreen={fullScreen}
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                    dialogAction(false);
                }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        setDialogOpen(false);
                        dialogAction(true);
                        event.preventDefault();
                    } else if (event.key === 'Escape') {
                        setDialogOpen(false);
                        dialogAction(false);
                        event.preventDefault();
                    }
                }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText data-testid="dialog.text">{dialogText}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={() => {
                            setDialogOpen(false);
                            dialogAction(false);
                        }}
                    >
                        {dialogCancel}
                    </Button>
                    <Button
                        onClick={() => {
                            setDialogOpen(false);
                            dialogAction(true);
                        }}
                    >
                        {dialogOk}
                    </Button>
                </DialogActions>
            </Dialog>

            <MaintenanceDialog
                open={showMaintenance}
                onClose={() => setShowMaintenance(false)}
                clearEvents={clearEvents}
                appAction={appAction}
            ></MaintenanceDialog>

            <ConnectionsDialog open={showConnections} onClose={() => setShowConnections(false)} appAction={appAction}></ConnectionsDialog>
        </>
    );
}
