import { useCallback, useState } from 'react';

import { KEY_TIMELINE_ZOOM } from '../utils/locale.storage.keys';
import { useConfig } from '../utils/use-config';

const INTERVAL_SAVE_NOW = 60_000;
const NOW_CURSOR_RANGE = 2 * INTERVAL_SAVE_NOW;
const DECAY_DELAY = 10 * INTERVAL_SAVE_NOW;

const interval: Array<NodeJS.Timeout> = [];

export const useTimeline = () => {
    const now = Date.now();
    const focus = 0.5;
    const [cursor, setCursor] = useState(now);
    const [saveNow, setSaveNow] = useState(now);

    const [decayCursorTime, setDecayCursorTime] = useState<number>(0); // next time, the cursor falls back to saveNow
    const [zoom, setZoom] = useConfig<number>(KEY_TIMELINE_ZOOM, 120);

    const onCursorUpdate = useCallback(
        (time: number) => {
            const now = Date.now();
            if (time > saveNow) {
                // avoid setting future cursors
                time = saveNow;
            }
            if (time !== cursor) {
                setDecayCursorTime(now + DECAY_DELAY);
            }
            setCursor(Math.floor(time));
        },
        [cursor, saveNow, setCursor, setDecayCursorTime]
    );

    const updateNow = useCallback((): number => {
        const now = Date.now();
        setSaveNow(now);
        const newCursor = decayCursorTime < now || Math.abs(cursor - now) <= NOW_CURSOR_RANGE ? now : cursor;
        setCursor(Math.floor(newCursor));
        return now;
    }, [setSaveNow, cursor, setCursor, decayCursorTime]);

    if (!interval.length) {
        interval.push(setInterval(updateNow, INTERVAL_SAVE_NOW));
    }

    return { cursor, focus, saveNow, zoom, updateNow, setCursor: onCursorUpdate, setZoom };
};
