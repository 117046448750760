import { EventEmitter } from 'stream';

import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ConnectionMode } from './connect/connection-mode';
import { Pairing } from './connect/pairing';
import { supportedLngs } from './i18n';
import { Event } from './timeline/event';
import { CellMode } from './tracking/cell-mode';
import { TrackingComponent } from './tracking/Tracking.component';
import { ACTION_CHANGE_EVENT, ACTION_CHANGE_LAYOUT } from './utils/action.keys';
import { initEventSources, postSSE, retryable } from './utils/event-utils';
import { initialLayout } from './utils/layout-utils';

const spectatorUpdate = [false];
const ui = (+('' + Math.random()).replace(/[,.]/g, '')).toString(36);

type ComponentProps = {
    appAction: EventEmitter;
};

export function Spectator({ appAction }: ComponentProps) {
    const { t, i18n } = useTranslation();
    const messageBar = useSnackbar();
    const params = useParams();
    const channel = params.channel;
    const lang = params.lang;
    if (lang && lang !== i18n.language && supportedLngs.includes(lang)) {
        i18n.changeLanguage(lang);
    }

    const sync = useMemo(
        () => ({
            pairings: {
                _spectator: {
                    active: true,
                    sync: ConnectionMode.SPECTATOR,
                    channel,
                    pairing: '_spectator',
                    displayName: 'Spectator',
                } as Pairing,
            },
            version: 0,
        }),
        [channel]
    );

    const [cursorEvent, setCursorEvent] = useState<Event>();
    const [layout, setLayout] = useState<string>(initialLayout(t));

    useEffect(() => {
        const changeEvent = (event: Event) => {
            if (event.n && !event.e) {
                setCursorEvent(event);
                document.title = event.n + ' - ' + t('tracking') + ' - ' + t('app.title');
                document.querySelector('meta[name="description"]').setAttribute('content', t('app.description'));
            } else if (event.i) {
                messageBar.enqueueSnackbar(format(event.s, 'HH:mm') + ' ' + event.i, { variant: 'info' });
            } else {
                setCursorEvent(undefined);
                document.title = t('tracking') + ' - ' + t('app.title');
                document.querySelector('meta[name="description"]').setAttribute('content', t('app.description'));
            }
        };
        const changeLayout = (l: string) => {
            setLayout(l);
        };
        appAction.on(ACTION_CHANGE_EVENT, changeEvent);
        appAction.on(ACTION_CHANGE_LAYOUT, changeLayout);
        return () => {
            appAction.off(ACTION_CHANGE_EVENT, changeEvent);
            appAction.off(ACTION_CHANGE_LAYOUT, changeLayout);
        };
    }, [appAction, setCursorEvent, messageBar, t, setLayout]);

    useEffect(() => {
        // listen to sync events/layouts
        const sses = initEventSources(
            sync,
            appAction,
            () => undefined, // spectator won't send updates
            ui
        );
        if (!spectatorUpdate[0]) {
            retryable(
                async () =>
                    await postSSE(sync.pairings['_spectator'], 'spectator', 'update').then(() => {
                        spectatorUpdate[0] = true;
                    }),
                3,
                1000
            );
        }
        return () => {
            sses.forEach((sse) => sse.close());
        };
    }, [sync, appAction]);

    return (
        <TrackingComponent
            spectatorMode={true}
            mode={CellMode.JUMP}
            cursorEvent={cursorEvent}
            onTrack={() => undefined}
            onMode={() => undefined}
            layout={layout}
            onLayout={() => undefined}
        />
    );
}
