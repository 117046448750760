import { FormControl, InputLabel, Stack, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import {
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnStrikeThrough,
    BtnStyles,
    BtnUnderline,
    ContentEditableEvent,
    Editor,
    EditorProvider,
    HtmlButton,
    Separator,
    Toolbar,
} from 'react-simple-wysiwyg';

import './RichTextEditor.css';

const buttonSx = {
    background: 'transparent',
    border: 0,
    color: '#222',
    cursor: 'pointer',
    fontSize: '1em',
    height: '2em',
    outline: 'none',
    padding: 0,
    width: '2em',
    '&:hover': {
        background: '#eaeaea',
    },
    '&[dataActive=true]': {
        background: '#e0e0e0',
    },
};

type ComponentProps = {
    label: string;
    value: string;
    setValue: (value: string) => void;
};
export function RichTextEditor({ label, value, setValue }: ComponentProps) {
    const theme = useTheme();

    const editorRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState('unset');
    const [height, setHeight] = useState('unset');

    useEffect(() => {
        if (editorRef.current) {
            setWidth(editorRef.current.offsetWidth + 'px');
            setHeight(editorRef.current.offsetHeight + 'px');
        }
    }, [editorRef, setWidth, setHeight]);

    return (
        <EditorProvider>
            <FormControl sx={{ width: '100%', height: '100%', arginTop: '-1rem' }} variant="outlined">
                <InputLabel
                    sx={{
                        position: 'absolute',
                        backgroundColor: theme.palette.background.default,
                        height: 'auto',
                        padding: '0 4px',
                        fontSize: '12px',
                        overflow: 'unset',
                        left: '1rem',
                        top: '-9px',
                    }}
                >
                    {label}
                </InputLabel>
                <Stack
                    sx={{
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        overflow: 'hidden',
                        borderRadius: '4px',
                        margin: '16px',
                        padding: '8px',
                    }}
                >
                    <Editor
                        ref={editorRef}
                        containerProps={{
                            style: {
                                resize: 'both',
                                border: 0,
                                borderRadius: 'unset',
                                color: theme.palette.primary.main,
                                width: width,
                                height: height,
                            },
                        }}
                        value={value}
                        onChange={(e: ContentEditableEvent) => {
                            setValue(e.target.value);
                        }}
                    >
                        <Toolbar
                            style={{
                                alignItems: 'center',
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #ddd',
                                display: 'flex',
                                marginTop: '0px',
                            }}
                        >
                            <BtnBold
                                style={{
                                    ...buttonSx,
                                }}
                            />
                            <BtnItalic
                                style={{
                                    ...buttonSx,
                                }}
                            />
                            <BtnUnderline
                                style={{
                                    ...buttonSx,
                                }}
                            />
                            <BtnStrikeThrough
                                style={{
                                    ...buttonSx,
                                }}
                            />
                            <Separator />
                            <BtnNumberedList
                                style={{
                                    ...buttonSx,
                                }}
                            />
                            <BtnBulletList
                                style={{
                                    ...buttonSx,
                                }}
                            />
                            <Separator />
                            <BtnLink
                                style={{
                                    ...buttonSx,
                                }}
                            />
                            <BtnClearFormatting
                                style={{
                                    ...buttonSx,
                                }}
                            />
                            <HtmlButton
                                style={{
                                    ...buttonSx,
                                }}
                            />
                            <Separator />
                            <BtnStyles
                                style={{
                                    ...buttonSx,
                                    width: 'auto',
                                }}
                            />
                        </Toolbar>
                    </Editor>
                </Stack>
            </FormControl>
        </EditorProvider>
    );
}
