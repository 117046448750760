import EventEmitter from 'events';

import { createTheme, ThemeProvider, PaletteMode, CssBaseline, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import cs from 'date-fns/locale/cs';
import da from 'date-fns/locale/da';
import de from 'date-fns/locale/de';
import el from 'date-fns/locale/el';
import en from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import iw from 'date-fns/locale/he';
import hi from 'date-fns/locale/hi';
import hu from 'date-fns/locale/hu';
import ja from 'date-fns/locale/ja';
import ko from 'date-fns/locale/ko';
import no from 'date-fns/locale/nb';
import nl from 'date-fns/locale/nl';
import pl from 'date-fns/locale/pl';
import ru from 'date-fns/locale/ru';
import sv from 'date-fns/locale/sv';
import tr from 'date-fns/locale/tr';
import uk from 'date-fns/locale/uk';
import vi from 'date-fns/locale/vi';
import zh from 'date-fns/locale/zh-CN';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { AppModals } from './AppModals';
import { ConnectionMode } from './connect/connection-mode';
import { correctDisplayName, generatePairingKey } from './connect/connection-utils';
import { Pairing } from './connect/pairing';
import { Sync } from './connect/sync';
import { Dashboard } from './Dashboard';
import { Buyer } from './invoice/Buyer';
import { Invoicing } from './invoice/Invoicing';
import { Layout } from './invoice/Layout';
import { PositionsEditor } from './invoice/PositionsEditor';
import { Seller } from './invoice/Seller';
import { Reporting } from './reporting/Reporting';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Spectator } from './Spectator';
import { Event } from './timeline/event';
import {
    ACTION_CHECK_SYNC,
    ACTION_NAVIGATE,
    ACTION_RELOAD_SYNC,
    ACTION_THEME_CHANGED,
    ACTION_USER_CHANGE_EVENT,
    ACTION_USER_CHANGE_LAYOUT,
} from './utils/action.keys';
import { clone } from './utils/clone';
import {
    clearAllEvents,
    getChecksums,
    getEventAt,
    getEvents,
    initEventSources,
    postEvent,
    postSSE,
    RESYNC_CHECK_INTERVAL,
    retryable,
    SYNC_CHECK_INTERVAL,
} from './utils/event-utils';
import { KEY_CONNECTIONS, KEY_LAYOUT, KEY_THEME_MODE } from './utils/locale.storage.keys';
import { clear, get, ui } from './utils/storage-impl';
import { useConfig } from './utils/use-config';

const adapterLocales = {
    cs,
    da,
    de,
    el,
    en,
    es,
    fr,
    ga: en, // workaround - irish not available
    hi,
    hu,
    iw,
    ja,
    ko,
    nl,
    no,
    pl,
    ru,
    sv,
    tr,
    uk,
    vi,
    zh,
};
const appAction = new EventEmitter();

const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                  // palette values for light mode
                  background: {
                      default: '#ffffff',
                      paper: '#ffffff',
                  },
                  primary: {
                      main: '#000000',
                  },
                  secondary: {
                      main: grey[700],
                  },
                  divider: grey[200],
                  text: {
                      primary: '#000000',
                      secondary: grey[500],
                  },
              }
            : {
                  // palette values for dark mode
                  background: {
                      default: '#000000',
                      paper: '#000000',
                  },
                  primary: {
                      main: '#ffffff',
                  },
                  secondary: {
                      main: grey[200],
                  },
                  divider: grey[700],
                  text: {
                      primary: '#ffffff',
                      secondary: grey[500],
                  },
              }),
    },
});

const dashboard = <Dashboard appAction={appAction} />;
const appModalPaths = [
    {
        path: 'export',
    },
    {
        path: 'import',
    },
    {
        path: 'connections',
    },
];
const router = createBrowserRouter([
    {
        path: ':lang?/',
        element: dashboard,
        children: appModalPaths,
    },
    {
        path: ':lang?/view/:channel',
        element: <Spectator appAction={appAction} />,
    },
    {
        path: ':lang?/invoice/',
        element: <Invoicing appAction={appAction} />,
        children: [
            {
                path: 'positions/',
                element: <PositionsEditor />,
                children: [
                    ...appModalPaths,
                    {
                        path: ':from/:to',
                    },
                ],
            },
            {
                path: 'positions/:from/:to',
                element: <PositionsEditor />,
            },
            {
                path: 'buyer/',
                element: <Buyer />,
                children: appModalPaths,
            },
            {
                path: 'seller/',
                element: <Seller />,
                children: appModalPaths,
            },
            {
                path: 'layout/',
                element: <Layout />,
                children: appModalPaths,
            },
        ],
    },
    {
        path: ':lang?/report',
        element: <Reporting appAction={appAction} />,
        children: [
            ...appModalPaths,
            {
                path: ':from/:to',
            },
        ],
    },
    {
        path: '*',
        element: <Navigate to="/" replace={true} />,
    },
]);

function App() {
    const { t, i18n } = useTranslation();
    const messageBar = useSnackbar();

    const [sync, setSync] = useConfig<Sync>(KEY_CONNECTIONS, { pairings: {}, version: 0 });
    const [adapterLocale] = useState(adapterLocales[i18n.language]);
    const [syncCheckRun, setSyncCheckRun] = useState(0);

    const [initThemeMode] = useConfig<PaletteMode>(KEY_THEME_MODE, 'dark');
    const [themeMode, setThemeMode] = useState(initThemeMode);

    useEffect(() => {
        const changeTheme = (newThemeMode) => setThemeMode(newThemeMode);
        appAction.on(ACTION_THEME_CHANGED, changeTheme);
        return () => {
            appAction.off(ACTION_THEME_CHANGED, changeTheme);
        };
    }, [setThemeMode]);

    const theme = useMemo(() => {
        return createTheme(getDesignTokens(themeMode));
    }, [themeMode]);

    const onSWUpdate = useCallback(
        (registration: ServiceWorkerRegistration) => {
            messageBar.enqueueSnackbar(t('update.available'), {
                variant: 'success',
                persist: true,
                preventDuplicate: true,
                action: (key) => (
                    <Fragment>
                        <Button
                            size="small"
                            variant="contained"
                            color="success"
                            onClick={() => {
                                if (registration && registration.waiting) {
                                    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                                }
                                window.location.reload();
                            }}
                        >
                            {t('update.now')}
                        </Button>
                        <Button
                            sx={{ marginLeft: '1rem' }}
                            size="small"
                            variant="contained"
                            color="success"
                            onClick={() => messageBar.closeSnackbar(key)}
                        >
                            {t('update.later')}
                        </Button>
                    </Fragment>
                ),
            });
        },
        [messageBar, t]
    );

    useMemo(() => {
        // check for updates of this app
        serviceWorkerRegistration.register({
            onUpdate: onSWUpdate,
        });
    }, [onSWUpdate]);

    useEffect(() => {
        let timer = undefined;
        const searchParams = new URLSearchParams(window.location.search);
        let navigateTo = undefined;
        let reloadWindow = false;
        if (['reset'].includes(searchParams.get('a') as string)) {
            clear();
            navigateTo = '/';
            reloadWindow = true;
        }

        // QR-Code configure sync/storage connections and redirect to '/' thereafter
        if (['a', 'v', 'p', 'e', 's', 'i'].includes(searchParams.get('m') as string)) {
            const newSync = clone(sync);

            // correct old syncs
            Object.keys(newSync.pairings).forEach((k) => {
                if (!newSync.pairings[k].channel) {
                    newSync.pairings[k].channel = ui();
                }
            });
            delete newSync['active'];
            delete newSync['channel'];

            const channel = searchParams.get('c') ?? ui();
            let syncMode = undefined;
            switch (searchParams.get('m')) {
                case 'a': // connect via stream
                    syncMode = ConnectionMode.SAME_USER;
                    break;
                case 'v': // connect via /view/ url
                    syncMode = ConnectionMode.SPECTATOR;
                    break;
                case 'p': // connect via badge
                    syncMode = ConnectionMode.PEEP;
                    break;
                case 'e': // push-jump-events-only via stream
                    syncMode = ConnectionMode.QRCODE_PUSH;
                    break;
                case 's': // storage
                    syncMode = ConnectionMode.STORAGE;
                    break;
                case 'i': // invoice
                    syncMode = ConnectionMode.INVOICE;
                    break;
                default:
                    navigateTo = '/';
                    return;
            }
            if (syncMode) {
                const pairingKey = generatePairingKey(syncMode, searchParams.get('p'));
                const displayName =
                    syncMode === ConnectionMode.INVOICE
                        ? (searchParams.get('d') ?? 'localhost:10801')
                        : correctDisplayName(newSync, pairingKey, syncMode, searchParams.get('d'));
                const pairing = sync.pairings[pairingKey]
                    ? sync.pairings[pairingKey]
                    : ({ active: true, channel, pairing: pairingKey, sync: syncMode, displayName } as Pairing);
                if (newSync.pairings[pairingKey] !== undefined) {
                    delete newSync.pairings[pairingKey];
                }
                newSync.pairings[pairingKey] = pairing;
                newSync.version = !sync.version ? 1 : sync.version + 1;
                setSync(newSync);
                appAction.emit(ACTION_RELOAD_SYNC, newSync);
                navigateTo = '/';
                if (syncMode === ConnectionMode.SAME_USER) {
                    timer = setTimeout(async () => {
                        clearAllEvents();
                        try {
                            await retryable(async () => await postSSE(pairing, 'sync', 'init', channel), 3, 1000);
                            // eslint-disable-next-line
                        } catch (error) {}
                    }, 500);
                }
            }
        }

        if (!!navigateTo) {
            appAction.emit(ACTION_NAVIGATE, '/');
        }
        if (reloadWindow) {
            const toHref = window.location.href.replace(/\?.*/, '');
            if (toHref !== window.location.href) {
                window.location.href = window.location.href.replace(/\?.*/, '');
            } else {
                window.location.reload();
            }
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [sync, setSync]);

    useEffect(() => {
        // listen to sync/storage invoices/events/layouts
        const sses = initEventSources(sync, appAction, (message: string, pairing: Pairing, source: string) => {
            if (message === 'update' && [ConnectionMode.SPECTATOR].includes(pairing.sync)) {
                const layout = get(KEY_LAYOUT);
                if (layout) {
                    postEvent(pairing, layout, source);
                }
            }
            if (message === 'update' && [ConnectionMode.SPECTATOR, ConnectionMode.PEEP].includes(pairing.sync)) {
                const event = getEventAt(Date.now());
                if (event) {
                    postEvent(pairing, event, source);
                }
            }
            if (message === 'init' && [ConnectionMode.SAME_USER].includes(pairing.sync)) {
                const layout = get(KEY_LAYOUT);
                // console.log('responding to init layout request from same user with ' + layout);
                if (layout) {
                    postEvent(pairing, layout, source);
                }
                const allEvents = getEvents(0, Number.MAX_VALUE);
                for (let i = 0; i < allEvents.length; i += 20) {
                    setTimeout(() => {
                        allEvents.slice(i, Math.min(i + 20, allEvents.length)).forEach((e) => postEvent(pairing, e, source));
                    }, i * 20);
                }
            }
        });

        let interval = undefined;
        let inital = undefined;
        if (
            !window.location.href.match(/\/view\//) &&
            Object.keys(sync.pairings)
                .map((k) => sync.pairings[k])
                .filter((p) => [ConnectionMode.STORAGE, ConnectionMode.SAME_USER].includes(p.sync)).length > 0
        ) {
            const syncCheck = async () => {
                if (!!syncCheckRun && syncCheckRun > Date.now() - SYNC_CHECK_INTERVAL) {
                    return;
                }
                const checksums = await getChecksums();
                let failed = false;
                await Promise.all(
                    Object.keys(sync.pairings)
                        .map((k) => sync.pairings[k])
                        .filter((p) => [ConnectionMode.STORAGE, ConnectionMode.SAME_USER].includes(p.sync))
                        .map(async (p) => {
                            try {
                                await retryable(async () => await postSSE(p, 'sync-check', JSON.stringify(checksums)), 3, 1000);
                            } catch (error) {
                                console.log('posting sync-check failed ' + error);
                                failed = true;
                            }
                        })
                );
                if (failed) {
                    setSyncCheckRun(0);
                } else {
                    setSyncCheckRun(Date.now());
                }
            };

            interval = setInterval(async () => {
                await syncCheck();
            }, RESYNC_CHECK_INTERVAL);
            inital = setTimeout(async () => {
                await syncCheck();
            }, 3000);
        }
        return () => {
            sses.forEach((sse) => sse.close());
            if (interval) {
                clearInterval(interval);
            }
            if (inital) {
                clearTimeout(inital);
            }
        };
    }, [sync, syncCheckRun]);

    useEffect(() => {
        const userChangeEvent = (event: Event) => {
            Object.values(sync.pairings).forEach(async (pairing) => {
                if (pairing.active) {
                    if (
                        [ConnectionMode.STORAGE, ConnectionMode.SAME_USER, ConnectionMode.SPECTATOR, ConnectionMode.PEEP].includes(
                            pairing.sync
                        )
                    ) {
                        await postEvent(pairing, event);
                    }
                }
            });
        };
        const userChangeLayout = (l: string) => {
            const layout = get(KEY_LAYOUT);
            if (l !== layout) {
                Object.values(sync.pairings).forEach(async (pairing) => {
                    if (pairing.active) {
                        if ([ConnectionMode.STORAGE, ConnectionMode.SAME_USER, ConnectionMode.SPECTATOR].includes(pairing.sync)) {
                            await postEvent(pairing, l);
                        }
                    }
                });
            }
        };
        const checkSync = () => {
            setSyncCheckRun(0);
        };
        const reloadSync = (newSync: Sync) => {
            setSync(newSync);
        };
        appAction.on(ACTION_USER_CHANGE_EVENT, userChangeEvent);
        appAction.on(ACTION_USER_CHANGE_LAYOUT, userChangeLayout);
        appAction.on(ACTION_CHECK_SYNC, checkSync);
        appAction.on(ACTION_RELOAD_SYNC, reloadSync);
        return () => {
            appAction.off(ACTION_USER_CHANGE_EVENT, userChangeEvent);
            appAction.off(ACTION_USER_CHANGE_LAYOUT, userChangeLayout);
            appAction.off(ACTION_CHECK_SYNC, checkSync);
            appAction.off(ACTION_RELOAD_SYNC, reloadSync);
        };
    }, [setSyncCheckRun, setSync, sync.pairings]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
            <CssBaseline enableColorScheme />
            <ThemeProvider theme={theme}>
                <RouterProvider router={router} />
                <AppModals appAction={appAction} />
            </ThemeProvider>
        </LocalizationProvider>
    );
}

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
            <App />
        </SnackbarProvider>
    );
}
