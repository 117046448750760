import { EventEmitter } from 'stream';

import { format, parseISO } from 'date-fns';

import { ConnectionMode } from '../connect/connection-mode';
import { Pairing } from '../connect/pairing';
import { Sync } from '../connect/sync';
import { Invoice } from '../invoice/Invoice';
import { getInvoiceKeys } from '../invoice/invoice-utils';
import { Event } from '../timeline/event';
import { Cell } from '../tracking/cell';

import { ACTION_CHANGE_EVENT, ACTION_CHANGE_LAYOUT, ACTION_CHECK_SYNC, ACTION_REFRESH, ACTION_UPDATE_INVOICES } from './action.keys';
import { csvTable } from './csv-utils';
import { dayEnd, dayStart } from './date-utils';
import { EventSourceWrapper } from './event-source-wrapper';
import { groupBy } from './group-by';
import { KEY_LAYOUT, KEY_LAYOUT_CHANGED, PREFIX_BUYER, PREFIX_EVENTS, PREFIX_INVOICE } from './locale.storage.keys';
import { createId, deleteKey, get, getKeys, put, ui } from './storage-impl';

export const SEPERATOR_EVENTS = `\n`;
const SEPERATOR_PARTS = `\t`;
export const RESYNC_CHECK_INTERVAL = 10_000 - Math.random() * 5000;
export const SYNC_CHECK_INTERVAL = 3600_000;
const PARTS_CHUNK = 4000;

export function eventFromString(s: string): Event {
    const parts = s.split(SEPERATOR_PARTS);
    const e = { s: +parts[0] } as Event;
    switch (parts.length) {
        case 2: // info
            e.i = parts[1];
            break;
        case 3: // activity without end
            e.n = parts[1];
            e.c = parts[2];
            break;
        case 4: // activity with end
            e.n = parts[1];
            e.c = parts[2];
            e.e = +parts[3];
            break;
        default:
            throw new Error('not recognizable as an event: "' + s + '"');
    }
    return e;
}

export function eventToString(e: Event) {
    let s = '' + e.s;
    if (!!e.i) {
        s += SEPERATOR_PARTS + e.i;
    } else {
        s += SEPERATOR_PARTS + e.n + SEPERATOR_PARTS + e.c;
        if (e.e) {
            s += SEPERATOR_PARTS + e.e;
        }
    }
    return s;
}

export function eventsToString(e: Event[]): string {
    return e.map(eventToString).join(SEPERATOR_EVENTS);
}

function compareEvents(a: Event, b: Event) {
    if (a.s > b.s) {
        return 1;
    }
    if (a.s < b.s) {
        return -1;
    }
    if (!!a.i && !b.i) {
        return 1;
    }
    if (!a.i && !!b.i) {
        return -1;
    }
    return (a.n + a.i).localeCompare(b.n + b.i);
}

export function addEventToStrings(s: string, e: Event): Event[] {
    const events = !!s ? s.split(SEPERATOR_EVENTS).map(eventFromString) : [];
    const replace = events.findIndex((o) => o.s === e.s && (o.i !== undefined) === (e.i !== undefined));
    if (replace !== -1) {
        events.splice(replace, 1);
    }
    if (!e.i) {
        const cutoff = events.findIndex((o) => o.s < e.s && (o.e === undefined || o.e > e.s) && o.i === undefined);
        if (cutoff !== -1) {
            if (!e.e) {
                e.e = events[cutoff].e;
            }
            events[cutoff].e = e.s;
        } else if (!e.e) {
            const after = events.findIndex((o) => o.s > e.s && o.i === undefined);
            if (after !== -1) {
                e.e = events[after].s;
            }
        }
    }
    events.push(e);
    events.sort(compareEvents);
    return events.filter((e) => e.i !== ''); // filter out empty infos
}

export function getNextEventDayEnd(from: number): number {
    const available = getKeys()
        .filter((k) => k.startsWith(PREFIX_EVENTS))
        .sort();

    const fromDay = dayStart(from);
    const i = available.findIndex((k) => parseISO(k.substring(PREFIX_EVENTS.length)).getTime() > fromDay);
    if (i === -1) {
        return dayEnd(from);
    }
    return dayEnd(parseISO(available[i].substring(PREFIX_EVENTS.length)).getTime());
}

export function getEventDayKeys(): string[] {
    return getKeys()
        .filter((k) => k.startsWith(PREFIX_EVENTS))
        .sort();
}

function getDayEvents(key: string) {
    const es = get(key);
    return !!es ? es.split(SEPERATOR_EVENTS).map(eventFromString) : [];
}

export function mergeEvents(es1: string, es2: string) {
    let s = es1;
    const events2 = !!es2 ? es2.split(SEPERATOR_EVENTS).map(eventFromString) : [];
    events2.forEach((e) => {
        s = eventsToString(addEventToStrings(s, e));
    });
    return s;
}

export function getEvents(from: number, to: number) {
    if (from > to) {
        throw new Error('from after to');
    }
    // will return the activity before from, too
    const available = getEventDayKeys();

    const fromDay = dayStart(from);
    let f = Math.max(0, available.findIndex((k) => parseISO(k.substring(PREFIX_EVENTS.length)).getTime() >= fromDay) - 1);
    let activityFound = false;
    while (f > 0 && !activityFound) {
        const dayEvents = getDayEvents(available[f]);
        if (dayEvents.filter((e) => !!e.n).length) {
            activityFound = true;
        } else {
            f--;
        }
    }
    const results: Event[] = [];
    while (f < available.length) {
        const dayEvents = getDayEvents(available[f]);
        if (dayEvents.length > 0) {
            results.push(...dayEvents);
            if (dayEvents[dayEvents.length - 1].s > to) {
                break;
            }
        }
        f++;
    }
    let fromActivity = 0;
    let toEvent = -1;
    for (let t = 0; t < results.length; t++) {
        if (!!results[t].n && results[t].s < from) {
            fromActivity = t;
        }
        if (results[t].s <= to) {
            toEvent = t;
        } else {
            break;
        }
    }
    if (toEvent === -1) {
        return [];
    }
    return results.slice(fromActivity, toEvent + 1);
}

export function getEventAt(at: number) {
    const eventsAtTime = getEvents(at, at);
    const events = eventsAtTime.filter((e) => e.s <= at && e.n && (!e.e || e.e > at));
    if (events.length) {
        return events[events.length - 1];
    }
    return undefined;
}

export function addEvent(event: Event) {
    const date = format(new Date(event.s), 'yyyy-MM-dd');
    const s = get(PREFIX_EVENTS + date);
    const dayEvents = addEventToStrings(s, event);
    put(PREFIX_EVENTS + date, dayEvents.map(eventToString).join(SEPERATOR_EVENTS));
}

const DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT = '__#_#QuoTe#_#__';
const COMMA_IN_QUOTES_TEMP_REPLACEMENT = '__#_#CoMma#_#__';

export function validateEventsImport(s: string) {
    const rows = s.split('\n');
    if (rows.length === 0) {
        throw new Error('invalid format');
    }
    if (rows[0] !== 'unixtimestamp,event,color,end') {
        throw new Error('unexpected column format >' + s);
    }
    const p = [0];
    for (let l = 1; l < rows.length; l++) {
        const c = rows[l]
            .replaceAll(/""/g, DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT)
            .replaceAll(/"(.*?)"/g, (h) => h.replaceAll(',', COMMA_IN_QUOTES_TEMP_REPLACEMENT))
            .split(',');
        if (l === rows.length - 1 && c.length === 1 && c[0] === '') {
            continue;
        }
        if (c.length < 2 || c.length > 4) {
            throw new Error('unexpected number of columns ' + c.length);
        }
        if ('' + Math.floor(Math.abs(+c[0])) !== c[0] || p[0] > +c[0]) {
            throw new Error('invalid unixtimestamp ' + c[0]);
        }
        if (c[2] && !c[2].match(/#([0-9a-f]{3}){1,2}/)) {
            throw new Error('invalid color ' + c[2]);
        }
        if (c[3] && ('' + Math.floor(Math.abs(+c[3])) !== c[3] || +c[0] >= +c[3])) {
            throw new Error('invalid end ' + c[3]);
        }

        p[0] = +c[0];
    }
}

export function clearAllEvents() {
    getKeys()
        .filter((k) => k.startsWith(PREFIX_EVENTS))
        .forEach((k) => deleteKey(k));
}

export function importEvents(s: string, onEvent: (event: Event, userTriggered: boolean) => void) {
    const rows = s.split('\n');
    for (let l = 1; l < rows.length; l++) {
        const line = rows[l]
            .replaceAll(/""/g, DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT)
            .replaceAll(/"(.*?)"/g, (h) => h.replaceAll(',', COMMA_IN_QUOTES_TEMP_REPLACEMENT))
            .replaceAll(/"(.*?)"/g, '$1')
            .replace(/,+$/g, '');
        const c = line.split(',');
        if (l === rows.length - 1 && c.length === 1 && c[0] === '') {
            continue;
        }
        const e = { s: +c[0] } as Event;
        if (c.length === 2) {
            e.i = c[1].replaceAll(COMMA_IN_QUOTES_TEMP_REPLACEMENT, ',').replaceAll(DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT, '"');
        } else if (c.length === 3) {
            e.n = c[1].replaceAll(COMMA_IN_QUOTES_TEMP_REPLACEMENT, ',').replaceAll(DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT, '"');
            e.c = c[2];
        } else if (c.length === 4) {
            e.n = c[1].replaceAll(COMMA_IN_QUOTES_TEMP_REPLACEMENT, ',').replaceAll(DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT, '"');
            e.c = c[2];
            e.e = +c[3];
        } else {
            throw new Error('unexpected amount of columns ' + c.length);
        }
        onEvent(e, true);
    }
}

export const moveCursorToPrevious = (time: number, saveNow: number, onCursorChanged: (time: number, isNow: boolean) => void) => {
    const events = getEvents(time, time);
    const times = events.flatMap((e) => (e.i ? [e.s] : [e.s, e.e]));
    times.push(saveNow);
    times.sort();
    const previous = times.filter((t) => t < time);
    if (previous.length) {
        const to = previous[previous.length - 1];
        onCursorChanged(to, to === saveNow);
    }
};

export const moveCursorToNext = (time: number, saveNow: number, onCursorChanged: (time: number, isNow: boolean) => void) => {
    const to = getNextEventDayEnd(time);
    const events = getEvents(time, to);
    const times = events.flatMap((e) => (e.i ? [e.s] : [e.s, e.e]));
    times.push(saveNow);
    times.sort();
    const next = times.filter((t) => t > time);
    if (next.length) {
        const to = next[0];
        onCursorChanged(to, to === saveNow);
    }
};

function getMessagePairing(message: MessageEvent, sync: Sync, k: string, u: string): Pairing | undefined {
    const source = message.lastEventId;
    if (source === (u ?? ui())) {
        return undefined;
    }
    const pairings = Object.keys(sync.pairings)
        .map((pk) => sync.pairings[pk])
        .filter((p) => p.channel === k);
    if (pairings.length !== 1) {
        return undefined;
    }
    return pairings[0];
}

export function initEventSources(
    sync: Sync,
    appAction: EventEmitter,
    onMessage: (message: string, pairing: Pairing, target: string) => void,
    u: string | undefined = undefined
): EventSourceWrapper[] {
    let syncPartsRun = 0;
    const syncParts = {};
    const channels = groupBy(
        Object.keys(sync.pairings)
            .filter(
                (k) =>
                    [
                        ConnectionMode.STORAGE,
                        ConnectionMode.QRCODE_PUSH,
                        ConnectionMode.SAME_USER,
                        ConnectionMode.SPECTATOR,
                        ConnectionMode.PEEP,
                    ].includes(sync.pairings[k].sync) && sync.pairings[k].active
            )
            .map((k) => sync.pairings[k]),
        (p) => p.channel
    );

    return Object.keys(channels).map((k) => {
        const listeners = {
            event: async (message) => {
                if (!getMessagePairing(message, sync, k, u)) {
                    return;
                }
                appAction.emit(ACTION_CHANGE_EVENT, JSON.parse(message.data) as Event);
            },
            spectator: async (message) => {
                const p = getMessagePairing(message, sync, k, u);
                if (!p) {
                    return;
                }
                onMessage(message.data, p, message.lastEventId);
            },
            peep: async (message) => {
                const p = getMessagePairing(message, sync, k, u);
                if (!p) {
                    return;
                }
                onMessage(message.data, p, message.lastEventId);
            },
            sync: async (message) => {
                const p = getMessagePairing(message, sync, k, u);
                if (!p) {
                    return;
                }
                onMessage(message.data, p, message.lastEventId);
            },
            'sync-check': async (message) => {
                const p = getMessagePairing(message, sync, k, u);
                if (!p) {
                    return;
                }
                if (syncPartsRun < Date.now() - 120_000) {
                    Object.keys(syncParts).map((key) => delete syncParts[key]);
                }
                
                const localChecksums = await getChecksums();
                const remoteChecksums = JSON.parse(message.data);
                const layout = get(KEY_LAYOUT);
                if (
                    !!layout &&
                    (remoteChecksums[KEY_LAYOUT] === undefined ||
                        (remoteChecksums[KEY_LAYOUT] !== localChecksums[KEY_LAYOUT] &&
                            remoteChecksums[KEY_LAYOUT_CHANGED] <= localChecksums[KEY_LAYOUT_CHANGED]))
                ) {
                    await postEvent(p, layout, u);
                }
                const toMerge = {};
                let invoicesToMerge = 0;
                Object.keys(remoteChecksums).map(async (key) => {
                    if (key.startsWith(PREFIX_EVENTS) || (key.startsWith(PREFIX_INVOICE) && invoicesToMerge < 1)) {
                        const content = get(key);
                        if (!!content && remoteChecksums[key] !== localChecksums[key]) {
                            if (key.startsWith(PREFIX_INVOICE)) {
                                invoicesToMerge++;
                            }
                            console.log('merging ' + key);
                            toMerge[key] = content;
                        }
                    }
                    delete localChecksums[key];
                });
                Object.keys(localChecksums).map(async (key) => {
                    if (key.startsWith(PREFIX_EVENTS) || (key.startsWith(PREFIX_INVOICE) && invoicesToMerge < 1)) {
                        const content = get(key);
                        if (!!content) {
                            if (key.startsWith(PREFIX_INVOICE)) {
                                invoicesToMerge++;
                            }
                            console.log('merging ' + key);
                            toMerge[key] = content;
                        }
                    }
                });
                if (Object.keys(toMerge).length > 0) {
                    let json = undefined;
                    try {
                        json = JSON.stringify(toMerge);
                    } catch (error) {
                        console.log('sync-merge serializing toMerge failed ' + error);
                    }
                    if (json) {
                        if (json.length < PARTS_CHUNK) {
                            await postSSE(p, 'sync-merge', json, u);
                        } else {
                            const checksum = await getChecksum(json);
                            const parts = chunks(btoa(encodeURIComponent(json)), PARTS_CHUNK);
                            const identifier = createId();
                            console.log('sync-part ' + identifier + ' with ' + parts.length + ' parts');
                            for (let i = 0; i < parts.length; i++) {
                                await retryable(
                                    async () => await postSSE(p, 'sync-part', identifier + ':' + i + '=' + parts[i], u),
                                    3,
                                    1000
                                );
                            }
                            await retryable(
                                async () => await postSSE(p, 'sync-merge-complete', identifier + ':' + parts.length + '=' + checksum, u),
                                3,
                                1000
                            );
                        }
                    }
                }
            },
            'sync-part': async (message) => {
                if (!getMessagePairing(message, sync, k, u)) {
                    return;
                }
                const s = message.data as string;
                const a = s.indexOf(':');
                const o = s.indexOf('=');
                if (o === -1 || o > 30 || a === -1 || a >= o) {
                    console.log('corrupt part detected ' + s);
                    return;
                }
                const identifier = s.substring(0, a);
                const index = +s.substring(a + 1, o);
                const part = s.substring(o + 1);
                syncPartsRun = Date.now();
                if (!syncParts[identifier]) {
                    syncParts[identifier] = {};
                }
                syncParts[identifier][index] = part;
            },
            'sync-merge-complete': async (message) => {
                if (!getMessagePairing(message, sync, k, u)) {
                    return;
                }
                const s = message.data as string;
                syncPartsRun = Date.now();
                const m = await completeParts(s, syncParts);
                if (!m) {
                    appAction.emit(ACTION_CHECK_SYNC);
                    return;
                }
                await listeners['sync-merge']({ lastEventId: message.lastEventId, data: m });
            },
            'sync-merge': async (message) => {
                if (!getMessagePairing(message, sync, k, u)) {
                    return;
                }
                let toMerge = undefined;
                try {
                    toMerge = JSON.parse(message.data);
                } catch (e) {
                    console.log('sync-merge JSON parse failed: ' + e);
                    return;
                }
                Object.keys(toMerge).forEach((key) => {
                    if (key.startsWith(PREFIX_EVENTS)) {
                        let content = get(key);
                        try {
                            const s = toMerge[key];
                            s.split(SEPERATOR_EVENTS).map(eventFromString);
                            content = mergeEvents(content, s);
                            put(key, content);
                        } catch (e) {
                            console.log('sync-merge ' + key + ' failed: ' + e);
                        }
                    } else if (key.startsWith(PREFIX_INVOICE)) {
                        const b = JSON.parse(get(key)) as Invoice;
                        try {
                            const s = toMerge[key];
                            const a = JSON.parse(s) as Invoice;
                            if (!b || a.lastGenerated > b.lastGenerated) {
                                put(key, s);
                                put(
                                    PREFIX_BUYER + a.buyerName.replaceAll(/[^0-9a-zA-Z\.]/g, '_'),
                                    JSON.stringify({
                                        oration: a.oration,
                                        buyerName: a.buyerName,
                                        buyerAddress: a.buyerAddress,
                                        buyerEmail: a.buyerEmail,
                                    })
                                );
                            }
                        } catch (e) {
                            console.log('sync-merge ' + key + ' failed: ' + e);
                        }
                    }
                });
                appAction.emit(ACTION_UPDATE_INVOICES);
                appAction.emit(ACTION_REFRESH);
                appAction.emit(ACTION_CHECK_SYNC);
            },
            'sync-override-complete': async (message) => {
                if (!getMessagePairing(message, sync, k, u)) {
                    return;
                }
                const s = message.data as string;
                syncPartsRun = Date.now();
                const m = await completeParts(s, syncParts);
                if (!m) {
                    appAction.emit(ACTION_CHECK_SYNC);
                    return;
                }
                await listeners['sync-override']({ lastEventId: message.lastEventId, data: m });
            },
            'sync-override': async (message) => {
                if (!getMessagePairing(message, sync, k, u)) {
                    return;
                }
                let toOverride = undefined;
                try {
                    toOverride = JSON.parse(message.data);
                } catch (e) {
                    console.log('sync-override JSON parse failed: ' + e);
                    return;
                }
                Object.keys(toOverride).forEach((key) => {
                    if (toOverride[key] === '') {
                        deleteKey(key);
                    } else if (key.startsWith(PREFIX_EVENTS)) {
                        try {
                            const s = toOverride[key];
                            s.split(SEPERATOR_EVENTS).map(eventFromString);
                            put(key, s);
                        } catch (e) {
                            console.log('sync-override skipping corrupted ' + key + ': ' + e);
                        }
                    } else if (key === KEY_LAYOUT) {
                        try {
                            const l = toOverride[key];
                            Cell.fromJson(l);
                            put(key, l);
                        } catch (e) {
                            console.log('sync-override skipping corrupted ' + key + ': ' + e);
                        }
                    }
                });
                appAction.emit(ACTION_REFRESH);
                appAction.emit(ACTION_CHECK_SYNC);
            },
            layout: async (message) => {
                if (!getMessagePairing(message, sync, k, u)) {
                    return;
                }
                appAction.emit(ACTION_CHANGE_LAYOUT, message.data);
            },
            composite: async (message) => {
                if (!getMessagePairing(message, sync, k, u)) {
                    return;
                }
                const data = JSON.parse(message.data);
                if (Array.isArray(data)) {
                    data.forEach((value) => {
                        if (typeof value['s'] === 'number') {
                            appAction.emit(ACTION_CHANGE_EVENT, value as Event);
                        } else if (typeof value['r'] === 'object') {
                            appAction.emit(ACTION_CHANGE_LAYOUT, JSON.stringify(value));
                        } else {
                            console.log('unknown composite element ' + JSON.stringify(value));
                        }
                    });
                }
            },
        };
        return new EventSourceWrapper(`https://time2.event.emphasize.de/?u=${u ?? ui()}&topic=${k}`, listeners);
    });
}

async function completeParts(s: string, syncMergeParts: unknown, ) {
    const a = s.indexOf(':');
    const o = s.indexOf('=');
    if (o === -1 || o > 30 || a === -1 || a >= o) {
        console.log('corrupt part detected ' + s);
        return false;
    }
    const identifier = s.substring(0, a);
    const length = +s.substring(a + 1, o);
    const checksum = s.substring(o + 1);
    await new Promise((r) => setTimeout(r, 1000));
    if (!syncMergeParts[identifier]) {
        console.log('completed ' + identifier + ' but parts are missing');
        delete syncMergeParts[identifier];
        return false;
    }
    let waited = 0;
    for (let i = 0; i < length; i++) {
        while (!syncMergeParts[identifier][i]) {
            await new Promise((r) => setTimeout(r, 1000));
            waited++;
            if (waited > 10) {
                break;
            }
        }
    }
    let w = '';
    for (let i = 0; i < length; i++) {
        if (!syncMergeParts[identifier][i]) {
            console.log('completed ' + identifier + ' but part ' + i + ' is missing');
            delete syncMergeParts[identifier];
            
            return false;
        }
        w += syncMergeParts[identifier][i];
    }
    const m = decodeURIComponent(atob(w.replace(/\s/g, '')));
    delete syncMergeParts[identifier];
    const localChecksum = await getChecksum(m);
    if (checksum !== localChecksum) {
        console.log('completed ' + identifier + ' checksum mismatch ' + checksum + ' != ' + localChecksum);
        return false;
    }

    console.log('sync-parts-completed ' + identifier);
    return m;
}

let postBuffer = {};
let bufferCount = 0;
export async function postEvent(pairing: Pairing, e: Event | string, target: string | undefined = undefined) {
    if (bufferCount === 0) {
        setTimeout(async () => {
            if (bufferCount === 0) {
                return;
            }
            const buffer = postBuffer;
            postBuffer = {};
            bufferCount = 0;
            await Promise.all(
                Object.keys(buffer).map(async (key) => {
                    try {
                        await retryable(
                            async () =>
                                await postSSE(
                                    buffer[key].pairing,
                                    'composite',
                                    '[' + buffer[key].posts.join(',') + ']',
                                    buffer[key].target
                                ),
                            3,
                            1000
                        );
                    } catch (error) {
                        console.log('posting event failed ' + JSON.stringify(error) + ', retrying on next event');
                        addPostBuffer(buffer[key].pairing, buffer[key].posts.join(','), buffer[key].target);
                    }
                })
            );
        }, 500);
    }
    bufferCount++;

    addPostBuffer(pairing, e, target);
}

function addPostBuffer(pairing: Pairing, e: Event | string, target: string | undefined = undefined) {
    const key = pairing.channel + '-' + (target ?? '*');
    if (!postBuffer[key]) {
        postBuffer[key] = { pairing, target: target ?? '*', posts: [] };
    }
    postBuffer[key].posts.push(e['s'] ? JSON.stringify(e) : (e as string));
}

export async function postSSE(pairing: Pairing, type: string, data: string, target: string | undefined = undefined) {
    let url = 'https://time2.event.emphasize.de';
    if (pairing.sync === ConnectionMode.STORAGE) {
        url = (pairing.displayName.includes('://') ? '' : 'https://') + pairing.displayName;
    }

    const json = 'event: ' + type + '\ndata: ' + data + '\n';
    return fetch(`${url}/?topic=${pairing.channel}${target !== undefined && target !== '*' ? '&target=' + target : ''}&u=${ui()}`, {
        body: json,
        method: 'POST',
    })
        .then((response) => {
            if (response.status >= 400 && response.status < 600) {
                throw new Error('Bad response from server ' + response.status);
            }
            return response;
        })
        .catch((error) => {
            throw error;
        });
}

export async function retryable(f: () => void, times: number, idleMs: number) {
    let error = undefined;
    for (let i = 0; i < times; i++) {
        try {
            await f();
            return;
        } catch (e) {
            error = e;
        }
        await new Promise((r) => setTimeout(r, idleMs));
    }
    if (error) {
        throw error;
    }
}

export function chunks(str, size) {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
        chunks[i] = str.substr(o, size);
    }

    return chunks;
}

export async function getChecksum(s: string): Promise<string> {
    const hash = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(s));
    return Array.from(new Uint8Array(hash))
        .map((b) => ('00' + b.toString(16)).slice(-2))
        .join('');
}

export async function getChecksums() {
    const checksums = {};
    const invoiceKeys = getInvoiceKeys();
    await Promise.all(
        invoiceKeys.map(async (key) => {
            checksums[key] = await getChecksum(get(key));
        })
    );
    const layout = get(KEY_LAYOUT);
    if (!!layout) {
        checksums[KEY_LAYOUT] = await getChecksum(layout);
        checksums[KEY_LAYOUT_CHANGED] = get(KEY_LAYOUT_CHANGED);
    }
    const eventDayKeys = getEventDayKeys();
    await Promise.all(
        eventDayKeys.map(async (day) => {
            checksums[day] = await getChecksum(get(day));
        })
    );
    return checksums;
}

export function downloadEvents() {
    const allEvents = getEvents(0, Number.MAX_VALUE);
    const blob = csvTable(
        allEvents.map((e) => [e.s, e.n || e.i, e.c, e.e]),
        ['unixtimestamp', 'event', 'color', 'end']
    );
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'time-emphasize_events_' + format(new Date(), 'yyyy-MM-dd_HH-mm-ss') + '.csv');
    a.click();
}
