import { format } from 'date-fns';

import { KEY_INVOICE, PREFIX_BUYER, PREFIX_INVOICE } from '../utils/locale.storage.keys';
import { deleteKey, get, getKeys, put } from '../utils/storage-impl';

import { Invoice } from './Invoice';

export async function loadInitials(key: string) {
    if (get('default_invoice')) {
        return;
    }
    const json = await fetch(`/initials/${key}.json`);
    if (json.status < 400) {
        const template = await json.json();
        put('default_invoice', JSON.stringify(template));
    }
    if (get('default_invoice_logo')) {
        return;
    }
    const logo = await fetch(`/initials/${key}.logo.svg`);
    if (logo.status < 400) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(await logo.blob());
        await new Promise((resolve) => {
            fileReader.onload = async (e) => {
                if (!e.target) {
                    resolve(e);
                    return;
                }
                const svg = e.target.result as string;
                put('default_invoice_logo', svg);
                resolve(e);
            };
        });
    }
    if (get('default_invoice_main')) {
        return;
    }
    const main = await fetch(`/initials/${key}.main.twig`);
    if (main.status < 400) {
        const mainTwig = await main.text();
        put('default_invoice_main', mainTwig);
    }
    if (get('default_invoice_footer')) {
        return;
    }
    const footer = await fetch(`/initials/${key}.footer.twig`);
    if (footer.status < 400) {
        const footerTwig = await footer.text();
        put('default_invoice_footer', footerTwig);
    }
}

export function resetInvoiceDefaults() {
    const invoice = JSON.parse(get(KEY_INVOICE)) as Invoice;
    if (!invoice) {
        return;
    }
    let resets = false;
    if (get('default_invoice_main') === invoice.main) {
        delete invoice.main;
        resets = true;
    }
    if (get('default_invoice_footer') === invoice.footer) {
        delete invoice.footer;
        resets = true;
    }
    if (invoice.assets?.length && get('default_invoice_logo') === invoice.assets[0]) {
        delete invoice.assets;
        resets = true;
    }
    const template = JSON.parse(get('default_invoice')) as Invoice;
    if (template) {
        for (const prop in template) {
            if (template[prop] && invoice[prop] === template[prop]) {
                delete invoice[prop];
                resets = true;
            }
        }
    }
    if (resets) {
        put(KEY_INVOICE, JSON.stringify(invoice));
    }
}

export function getInvoiceKeys(): string[] {
    return getKeys()
        .filter((k) => k.startsWith(PREFIX_INVOICE))
        .sort();
}

export function resetInvoicesAndBuyers() {
    const keys = getKeys().filter((k) => k.startsWith(KEY_INVOICE) || k.startsWith(PREFIX_BUYER));
    keys.forEach((key) => {
        deleteKey(key);
    });
}

const basicInvoiceKeys = [KEY_INVOICE, 'default_invoice_main', 'default_invoice_footer', 'default_invoice_logo', 'default_invoice'];

export function loadInvoices(data: unknown) {
    basicInvoiceKeys.forEach((key) => {
        if (data[key]) {
            put(key, data[key]);
        }
    });
    for (const key of Object.keys(data)) {
        if (!basicInvoiceKeys.includes(key) && key.startsWith(PREFIX_INVOICE)) {
            put(key, data[key]);
            const invoice = JSON.parse(data[key]) as Invoice;
            put(
                PREFIX_BUYER + invoice.buyerName.replaceAll(/[^0-9a-zA-Z\.]/g, '_'),
                JSON.stringify({
                    oration: invoice.oration,
                    buyerName: invoice.buyerName,
                    buyerAddress: invoice.buyerAddress,
                    buyerEmail: invoice.buyerEmail,
                })
            );
        }
    }
}

export function downloadInvoices() {
    const invoiceKeys = getInvoiceKeys();
    const keys = [...invoiceKeys, ...basicInvoiceKeys];
    const data = {};
    keys.forEach((key) => {
        data[key] = get(key);
    });
    const blob = new Blob([JSON.stringify(data)], { type: 'application/json;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'Time2Emphasize_' + format(new Date(), 'yyyy-MM-dd_HH-mm-ss') + '.invoices');
    a.click();
}
