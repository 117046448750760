import EventEmitter from 'events';

import MenuIcon from '@mui/icons-material/Menu';
import { Button, Menu as M, MenuItem, PaletteMode, Tooltip, styled, Switch } from '@mui/material';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { darkModeDay, darkModeNight } from '../assets/source-data';
import {
    ACTION_OPEN_CONNECTIONS,
    ACTION_OPEN_IMPRINT,
    ACTION_OPEN_LEGALS,
    ACTION_OPEN_MAINTENANCE,
    ACTION_THEME_CHANGED,
} from '../utils/action.keys';
import { useConfig } from '../utils/use-config';

export const DarkModeSwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `${darkModeDay}`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `${darkModeNight}`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

type ComponentProps = {
    context: 'tracking' | 'invoicing' | 'reporting';
    modeBar?: ReactElement;
    appAction: EventEmitter;
};

export function Menu({ context, modeBar, appAction }: ComponentProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [themeMode, setThemeMode] = useConfig<PaletteMode>('theme-mode', 'dark');

    return (
        <>
            <Tooltip arrow title={t('menu')}>
                <Button
                    size="large"
                    color="inherit"
                    data-testid="menu"
                    aria-label={t('menu')}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        setAnchorEl(event.currentTarget);
                    }}
                    sx={{ m: '1rem 0' }}
                >
                    <MenuIcon />
                </Button>
            </Tooltip>
            <M
                id="main-menu"
                aria-labelledby="main-button"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {!!modeBar && (
                    <MenuItem
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                        onClick={() => {
                            setAnchorEl(null);
                        }}
                    >
                        {modeBar}
                    </MenuItem>
                )}
                {context !== 'tracking' && (
                    <MenuItem
                        onClick={() => {
                            navigate('/');
                            setAnchorEl(null);
                        }}
                    >
                        {t('tracking')}
                    </MenuItem>
                )}
                {context !== 'invoicing' && (
                    <MenuItem
                        onClick={() => {
                            navigate('/invoice');
                            setAnchorEl(null);
                        }}
                    >
                        {t('invoicing')}
                    </MenuItem>
                )}
                {context !== 'reporting' && (
                    <MenuItem
                        onClick={() => {
                            navigate('/report');
                            setAnchorEl(null);
                        }}
                    >
                        {t('reporting')}
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        appAction.emit(ACTION_OPEN_MAINTENANCE);
                        setAnchorEl(null);
                    }}
                >
                    {t('maintenance')}
                </MenuItem>
                <MenuItem
                    data-testid="menu.connections"
                    onClick={() => {
                        appAction.emit(ACTION_OPEN_CONNECTIONS);
                        setAnchorEl(null);
                    }}
                >
                    {t('synchronize')}
                </MenuItem>
                <MenuItem
                    data-testid="menu.legals"
                    onClick={() => {
                        appAction.emit(ACTION_OPEN_LEGALS);
                        setAnchorEl(null);
                    }}
                >
                    {t('legals')}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        appAction.emit(ACTION_OPEN_IMPRINT);
                        setAnchorEl(null);
                    }}
                >
                    {t('imprint')}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        const newThemeMode = themeMode === 'dark' ? 'light' : 'dark';
                        setThemeMode(newThemeMode);
                        appAction.emit(ACTION_THEME_CHANGED, newThemeMode);
                    }}
                >
                    <DarkModeSwitch
                        checked={themeMode === 'dark'}
                        onChange={() => setThemeMode(themeMode === 'dark' ? 'light' : 'dark')}
                    ></DarkModeSwitch>{' '}
                    {t('theme')}
                </MenuItem>
            </M>
        </>
    );
}
