export const ACTION_IMPORT_EVENTS = 'import-events';
export const ACTION_IMPORT_LAYOUT = 'import-layout';
export const ACTION_IMPORT_INVOICES = 'import-invoices';
export const ACTION_OPEN_DIALOG = 'open-dialog';
export const ACTION_OPEN_MAINTENANCE = 'open-maintenance';
export const ACTION_OPEN_CONNECTIONS = 'open-connections';
export const ACTION_OPEN_LEGALS = 'open-legals';
export const ACTION_OPEN_IMPRINT = 'open-imprint';
export const ACTION_THEME_CHANGED = 'theme-changed';
export const ACTION_UPDATE_EVENTS = 'update-events';
export const ACTION_UPDATE_LAYOUT = 'update-layout';
export const ACTION_UPDATE_INVOICES = 'update-invoices';
export const ACTION_USER_CHANGE_EVENT = 'user-change-event';
export const ACTION_USER_CHANGE_LAYOUT = 'user-change-layout';
export const ACTION_CHANGE_EVENT = 'change-event';
export const ACTION_CHANGE_LAYOUT = 'change-layout';
export const ACTION_REFRESH = 'refresh';
export const ACTION_CHECK_SYNC = 'check-sync';
export const ACTION_RELOAD_SYNC = 'reload-sync';
export const ACTION_NAVIGATE = 'navigate';
