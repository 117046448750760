import { Box, Popover } from '@mui/material';
import React, { MouseEvent, useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { SketchPicker } from 'react-color';

import { Event } from '../timeline/event';
import { randColor } from '../utils/rand-color';
import { getSplitSection } from '../utils/split-section';

import { Avatar } from './Avatar.component';
import { Cell } from './cell';
import { CellMode } from './cell-mode';
import { CellComponent } from './Cell.component';

import './Tracking.component.css';

const DEFAULT_COLORS = ['#d0021b', '#f5a623', '#f8E71c', '#8b572a', '#7ed321', '#417505', '#bd10e0', '#9013fe'];

export type CellContextType = {
    spectatorMode: boolean;
    mode: CellMode;
    onClick: (cell: Cell, event: MouseEvent) => void;
    onNameChanged: (cell: Cell, key: string) => void;
    onColorChanged: (cell: Cell) => void;
    selected: Cell;
};

export const CellContext = React.createContext<CellContextType | null>(null);

type ComponentProps = {
    spectatorMode: boolean;
    mode: CellMode;
    cursorEvent: Event | undefined;
    onTrack: (name: string, color: string) => void;
    onMode: (change: CellMode) => void;
    layout: string;
    onLayout: (layout: string, userTriggered: boolean) => void;
};

export function TrackingComponent({ spectatorMode, mode, cursorEvent, onTrack, onMode, layout, onLayout }: ComponentProps) {
    const overlayRef = useRef<HTMLDivElement>(null);
    const [popupRef, setPopupRef] = useState<HTMLElement>();
    const [selectedCell, setSelectedCell] = useState<Cell>();
    const [jumpTo, setJumpTo] = useState<HTMLElement>();
    const [root, setRoot] = useState(Cell.fromJson(layout));
    const [colorPopupOpen, setColorPopupOpen] = useState(false);
    const [popupColor, setPopupColor] = useState('');
    const [presetColors, setPresetColors] = useState(DEFAULT_COLORS);

    useMemo(() => {
        setRoot(Cell.fromJson(layout));
    }, [layout, setRoot]);

    useEffect(() => {
        const colors = layout.match(/"c":"[^"]{7}"/g).map((s) => s.replace('"c":"', '').replace('"', ''));
        colors.sort();
        const uniq = colors.filter((n, i) => colors.indexOf(n) === i);
        uniq.push(...DEFAULT_COLORS);
        const uniq2 = uniq.filter((n, i) => uniq.indexOf(n) === i);
        setPresetColors(uniq2.slice(0, 16));
    }, [layout, setPresetColors]);

    const onClick = useCallback(
        (cell: Cell, event: MouseEvent<HTMLElement>) => {
            switch (mode) {
                case CellMode.RENAME:
                    onLayout(root.toJson(), true);
                    setJumpTo(event.currentTarget);
                    onTrack(cell.n, cell.c);
                    onMode(CellMode.JUMP);
                    break;
                case CellMode.JUMP:
                case CellMode.QR:
                    if (event.detail > 1) {
                        onMode(CellMode.RENAME);
                        setSelectedCell(cell);
                        break;
                    }
                    setJumpTo(event.currentTarget);
                    onTrack(cell.n, cell.c);
                    break;
                case CellMode.SPLIT:
                    const c = new Cell(cell.n, cell.c);
                    const b = randColor();
                    const n = new Cell('', b);
                    const section = getSplitSection(event);
                    switch (section) {
                        case 'bottom':
                            cell.setChildren('v', c, n);
                            break;
                        case 'left':
                            cell.setChildren('h', n, c);
                            break;
                        case 'right':
                            cell.setChildren('h', c, n);
                            break;
                        case 'top':
                            cell.setChildren('v', n, c);
                            break;
                        default:
                            throw new Error('undefined section ' + section);
                    }
                    onLayout(root.toJson(), true);
                    event.stopPropagation();
                    setSelectedCell(n);
                    onMode(CellMode.RENAME);
                    break;
                case CellMode.REMOVE:
                    let rootCell: Cell;
                    onMode(CellMode.JUMP);
                    if (cell._parent) {
                        const other = cell._parent.s.filter((c) => c !== cell)[0];
                        if (other.s) {
                            cell._parent.setChildren(other.l, other.s[0], other.s[1]);
                        } else {
                            cell._parent.setBox(other.n, other.c);
                        }
                        rootCell = cell._parent;
                    } else {
                        cell.n = '';
                        setSelectedCell(cell);
                        onMode(CellMode.RENAME);
                        rootCell = cell;
                    }
                    while (rootCell._parent) {
                        rootCell = rootCell._parent;
                    }
                    setRoot(rootCell);
                    onLayout(rootCell.toJson(), true);
                    break;
                case CellMode.COLOR:
                    setPopupRef(event.currentTarget);
                    setSelectedCell(cell);
                    setPopupColor(cell.c);
                    setColorPopupOpen(true);
                    break;
            }
        },
        [mode, setJumpTo, onTrack, onMode, onLayout, setColorPopupOpen, root, setPopupColor]
    );

    const findCell = useCallback(
        (name: string, color: string): HTMLDivElement | undefined => {
            const colorCompare = overlayRef.current?.getElementsByClassName('colorCompare').item(0) as HTMLDivElement;
            colorCompare.style.backgroundColor = color;
            const fields = overlayRef.current?.getElementsByClassName('field');
            for (let i = 0; i < fields.length; i++) {
                const field = fields.item(i) as HTMLDivElement;
                const style = window.getComputedStyle(field);
                if (field.innerText.trim() === name.trim() && style.backgroundColor === colorCompare.style.backgroundColor) {
                    return field;
                }
            }
            return undefined;
        },
        [overlayRef]
    );

    const onNameChanged = useCallback(
        (cell: Cell, key: string) => {
            onLayout(root.toJson(), true);
            if (key === 'Enter' || key === 'Escape') {
                onMode(CellMode.JUMP);
            }
        },
        [root, onLayout, onMode]
    );

    const onColorChanged = useCallback(() => {
        onLayout(root.toJson(), true);
    }, [root, onLayout]);

    useEffect(() => {
        if ((mode === CellMode.JUMP || mode === CellMode.QR) && cursorEvent && cursorEvent.n) {
            const found = findCell(cursorEvent.n, cursorEvent.c);
            if (found) {
                setJumpTo(found);
            } else {
                setJumpTo(undefined);
            }
        } else {
            setJumpTo(undefined);
        }
    }, [mode, cursorEvent, root, findCell, setJumpTo]);

    const handlePopoverClose = useCallback(() => {
        setColorPopupOpen(false);
        selectedCell.c = popupColor;
        onLayout(root.toJson(), true);
        onMode(CellMode.JUMP);
    }, [setColorPopupOpen, onMode, root, onLayout, selectedCell, popupColor]);

    const cellContext = { spectatorMode, mode, onClick, onNameChanged, onColorChanged, selected: selectedCell } as CellContextType;

    return (
        <Box ref={overlayRef} sx={{ display: 'flex', flex: 1 }}>
            <div className="colorCompare" style={{ display: 'none' }} />
            <CellContext.Provider value={cellContext}>
                <CellComponent cell={root} />
            </CellContext.Provider>
            <Avatar overlay={overlayRef.current} jumpTo={jumpTo} />
            <Popover
                id="color-chooser"
                open={colorPopupOpen}
                anchorEl={popupRef}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <SketchPicker
                    disableAlpha
                    presetColors={presetColors}
                    color={popupColor}
                    onChangeComplete={(color) => {
                        setPopupColor(color.hex);
                    }}
                />
            </Popover>
        </Box>
    );
}
