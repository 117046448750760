import EventEmitter from 'events';

import PaymentsIcon from '@mui/icons-material/Payments';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    IconButton,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    useTheme,
} from '@mui/material';
import { isAfter, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { supportedLngs } from '../i18n';
import { BottomToolbar } from '../toolbar/BottomToolbar';
import { ACTION_CHECK_SYNC, ACTION_OPEN_DIALOG, ACTION_UPDATE_INVOICES } from '../utils/action.keys';
import { KEY_INVOICE, PREFIX_BUYER, PREFIX_INVOICE } from '../utils/locale.storage.keys';
import { get, put } from '../utils/storage-impl';
import { useConfig } from '../utils/use-config';

import { Invoice } from './Invoice';
import { getInvoiceKeys, loadInitials, resetInvoiceDefaults } from './invoice-utils';

function nextUniqueInvoiceNumber(invoices: string[], invoiceNumber: string): string {
    let number = invoiceNumber;
    while (invoices.includes(number)) {
        number = nextInvoiceNumber(number);
    }
    return number;
}

function nextInvoiceNumber(invoiceNumber: string): string {
    const p = (invoiceNumber ?? '').split(/([^0-9])/);
    const u = [];
    let success = false;
    let i = p.length;
    while (i) {
        i--;
        const n = p[i];
        if (!isNaN(parseFloat(n)) && !success) {
            const m = +n + 1;
            const x = '0'.repeat(n.length - (m + '').length) + m;
            u.unshift(x);
            success = true;
        } else {
            u.unshift(n);
        }
    }
    if (!success) {
        u.push('.001');
    }
    return u.join('');
}

export type InvoiceContextType = {
    version: number;
    invoice: Invoice;
    setInvoice: (newInvoice: Invoice) => void;
    errors: string[];
};

export const InvoiceContext = React.createContext<InvoiceContextType | null>(null);

type ComponentProps = {
    appAction: EventEmitter;
};

export function Invoicing({ appAction }: ComponentProps) {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const bottomBarRef = useRef<HTMLElement>(null);
    const messageBar = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const lang = params.lang;
    if (lang && lang !== i18n.language && supportedLngs.includes(lang)) {
        resetInvoiceDefaults();
        i18n.changeLanguage(lang);
    }

    const [invoices, setInvoices] = useState<string[]>([]);
    const [existing, setExisting] = useState(false);
    const [version, setVersion] = useState(0);
    const [open, setOpen] = useState('positions');
    const [invoice, setInvoice] = useConfig(KEY_INVOICE, {} as Invoice);
    const [generating, setGenerating] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [submitExceptions, setSubmitExceptions] = useState('');
    const [json, setJson] = useState('');

    useMemo(() => {
        setExisting(invoices.includes(invoice.invoiceNumber));
    }, [setExisting, invoice, invoices]);

    useMemo(() => {
        if (version > -1) {
            // dummy condition, always true
            setInvoices(
                getInvoiceKeys()
                    .map((k) => JSON.parse(get(k)).invoiceNumber)
                    .sort()
            );
        }
    }, [setInvoices, version]);

    const [initialLoaded, setInitialLoaded] = useState(false);
    useEffect(() => {
        let active = true;
        if (initialLoaded) {
            return;
        }
        const key = params.to === 'init' ? params.from : i18n.language.substring(0, 2);
        loadInitials(key).then(() => {
            if (!active) {
                return;
            }
            setInitialLoaded(true);
        });
        return () => {
            active = false;
        };
    }, [params, i18n, initialLoaded, setInitialLoaded]);

    useMemo(() => {
        if (!initialLoaded) {
            return;
        }
        let newInvoice: Invoice;
        if (!invoice.filename && get('default_invoice')) {
            if (!newInvoice) {
                newInvoice = { ...invoice, filename: '_' };
            }
            const template = JSON.parse(get('default_invoice')) as Invoice;
            for (const prop in template) {
                if (template[prop] && (!newInvoice[prop] || prop === 'positions')) {
                    newInvoice[prop] = template[prop];
                }
            }
        }
        if (!invoice.assets && get('default_invoice_logo')) {
            if (!newInvoice) {
                newInvoice = { ...invoice };
            }
            const svg = get('default_invoice_logo');
            newInvoice.assets = [svg];
        }
        if (!invoice.main && get('default_invoice_main')) {
            if (!newInvoice) {
                newInvoice = { ...invoice };
            }
            const mainTwig = get('default_invoice_main');
            newInvoice.main = mainTwig;
        }
        if (!invoice.footer && get('default_invoice_footer')) {
            if (!newInvoice) {
                newInvoice = { ...invoice };
            }
            const footerTwig = get('default_invoice_footer');
            newInvoice.footer = footerTwig;
        }
        if (newInvoice) {
            setInvoice(newInvoice);
            appAction.emit(ACTION_UPDATE_INVOICES);
        }
    }, [setInvoice, invoice, appAction, initialLoaded]);

    useEffect(() => {
        if (location.pathname.endsWith('/invoice') || location.pathname.endsWith('/invoice/')) {
            navigate('/invoice/positions');
        }
        const matches = location.pathname.match(/\/invoice\/([^\/]+).*/);
        if (matches && matches.length === 2) {
            setOpen(matches[1]);
        }
    }, [navigate, location]);

    useEffect(() => {
        document.title = t('invoicing.' + open) + ' - ' + t('invoicing') + ' - ' + t('app.title');
        document.querySelector('meta[name="description"]').setAttribute('content', t('app.description'));
    }, [open, t]);

    useEffect(() => {
        const updateInvoices = () => setVersion((v) => v + 1);
        appAction.on(ACTION_UPDATE_INVOICES, updateInvoices);
        return () => {
            appAction.off(ACTION_UPDATE_INVOICES, updateInvoices);
        };
    }, [setVersion, appAction]);

    useEffect(() => {
        if (!!submitExceptions) {
            appAction.emit(ACTION_OPEN_DIALOG, {
                title: t('failed') + ': ' + t('invoicing.generate'),
                text: submitExceptions,
                ok: t('ok'),
                action: (ok: boolean) => {
                    if (ok) {
                        setSubmitExceptions('');
                    }
                },
            });
        }
    }, [t, submitExceptions, appAction, setSubmitExceptions]);

    const invoiceContext = { version, invoice, setInvoice, errors } as InvoiceContextType;

    const bh = Math.max(bottomBarRef.current?.offsetHeight ?? 77, 77);
    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.background.default,
                paddingTop: '1rem',
            }}
        >
            <Box sx={{ overflow: 'auto', marginBottom: bh + 'px' }}>
                <InvoiceContext.Provider value={invoiceContext}>
                    <Outlet />
                </InvoiceContext.Provider>
            </Box>
            <BottomToolbar
                bottomBarRef={bottomBarRef}
                appAction={appAction}
                context="invoicing"
                appBar={
                    <>
                        <Box sx={{ marginRight: '1rem' }}>
                            <ToggleButtonGroup
                                color="standard"
                                value={open}
                                exclusive
                                onChange={(event, value) => {
                                    if (value) {
                                        navigate('/invoice/' + value + '/');
                                        setOpen(value);
                                    }
                                }}
                            >
                                <Tooltip arrow key="positions" title={t('invoicing.positions')}>
                                    <ToggleButton aria-label={t('invoicing.positions')} value={'positions'} sx={{ border: 0 }}>
                                        <ShoppingCartIcon color="primary" />
                                        <span className="sm-hide">{t('invoicing.positions')}</span>
                                    </ToggleButton>
                                </Tooltip>
                                <Tooltip arrow key="buyer" title={t('invoicing.buyer')}>
                                    <ToggleButton aria-label={t('invoicing.buyer')} value={'buyer'} sx={{ border: 0 }}>
                                        <PaymentsIcon color="primary" />
                                        <span className="sm-hide">{t('invoicing.buyer')}</span>
                                    </ToggleButton>
                                </Tooltip>
                                <FormControl sx={{ height: '100%', margin: 'auto', minWidth: '12rem' }}>
                                    <Autocomplete
                                        id="invoiceNumber"
                                        freeSolo
                                        options={invoices}
                                        value={invoice.invoiceNumber || null}
                                        onChange={(event: React.SyntheticEvent, newValue: string | null) => {
                                            const newInvoice = JSON.parse(
                                                get(PREFIX_INVOICE + (newValue ?? '').replaceAll(/[^0-9a-zA-Z\.]/g, '_'))
                                            );
                                            if (newInvoice) {
                                                setInvoice(newInvoice);
                                            }
                                        }}
                                        onInputChange={(event: React.SyntheticEvent, value: string, reason: string) => {
                                            if (reason === 'input') {
                                                const newValue = (value ?? '').trim();
                                                const newInvoice = { ...invoice, invoiceNumber: newValue };
                                                setInvoice(newInvoice);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t('invoicing.number.label')}
                                                error={errors.includes('invoiceNumber')}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {existing && (
                                                                <Tooltip arrow title={t('invoicing.number.exists')}>
                                                                    <IconButton
                                                                        aria-label={t('invoicing.number.exists')}
                                                                        onClick={() =>
                                                                            setInvoice({
                                                                                ...invoice,
                                                                                invoiceNumber: nextUniqueInvoiceNumber(
                                                                                    invoices,
                                                                                    invoice.invoiceNumber
                                                                                ),
                                                                            })
                                                                        }
                                                                        edge="end"
                                                                    >
                                                                        <WarningIcon color="warning" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                            {params.InputProps.endAdornment}
                                                        </>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <Box sx={{ flexGrow: 1, display: 'inline-flex' }}>
                                    <form
                                        action="https://time2.invoice.emphasize.de/"
                                        method="post"
                                        target="_blank"
                                        onSubmit={(e) => {
                                            const exceptions = [];
                                            const newErrors = [];
                                            if (!invoice.invoiceNumber?.trim()) {
                                                if (!newErrors.includes('invoiceNumber')) newErrors.push('invoiceNumber');
                                                exceptions.push(t('invoicing.invoiceNumber.empty'));
                                            }
                                            if (isAfter(parseISO(invoice.invoiceFrom ?? ''), parseISO(invoice.invoiceTo ?? ''))) {
                                                exceptions.push(t('invoicing.invoiceFrom.afterTo'));
                                            }
                                            if (!invoice.positions?.length) {
                                                if (!newErrors.includes('positions')) newErrors.push('positions');
                                                exceptions.push(t('invoicing.positions.empty'));
                                                navigate('/invoice/positions/');
                                            }
                                            if (invoice.positions?.filter((p) => !p.amount || !p.netto).length) {
                                                if (!newErrors.includes('positions')) newErrors.push('positions');
                                                exceptions.push(t('invoicing.positions.empty'));
                                                navigate('/invoice/positions/');
                                            }
                                            if (!invoice.buyerName?.trim()) {
                                                if (!newErrors.includes('buyerName')) newErrors.push('buyerName');
                                                exceptions.push(t('invoicing.buyerName.empty'));
                                                navigate('/invoice/buyer/');
                                            }
                                            if (!invoice.buyerEmail?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                                                if (!newErrors.includes('buyerEmail')) newErrors.push('buyerEmail');
                                                exceptions.push(t('invoicing.buyerEmail.invalid'));
                                                navigate('/invoice/buyer/');
                                            }
                                            if (!invoice.sellerName?.trim()) {
                                                if (!newErrors.includes('sellerName')) newErrors.push('sellerName');
                                                exceptions.push(t('invoicing.sellerName.empty'));
                                                navigate('/invoice/seller/');
                                            }
                                            if (!invoice.sellerEmail?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                                                if (!newErrors.includes('sellerEmail')) newErrors.push('sellerEmail');
                                                exceptions.push(t('invoicing.sellerEmail.invalid'));
                                                navigate('/invoice/seller/');
                                            }
                                            if (!invoice.iban?.trim()) {
                                                if (!newErrors.includes('iban')) newErrors.push('iban');
                                                exceptions.push(t('invoicing.iban.empty'));
                                                navigate('/invoice/seller/');
                                            }
                                            if (!invoice.bic?.trim()) {
                                                if (!newErrors.includes('bic')) newErrors.push('bic');
                                                exceptions.push(t('invoicing.bic.empty'));
                                                navigate('/invoice/seller/');
                                            }
                                            if (!invoice.vatId?.trim()) {
                                                if (!newErrors.includes('vatId')) newErrors.push('vatId');
                                                exceptions.push(t('invoicing.vatId.empty'));
                                                navigate('/invoice/seller/');
                                            }
                                            if (!invoice.currency?.trim()) {
                                                if (!newErrors.includes('currency')) newErrors.push('currency');
                                                exceptions.push(t('invoicing.currency.empty'));
                                                navigate('/invoice/seller/');
                                            }

                                            if (exceptions.length) {
                                                e.preventDefault();
                                                setSubmitExceptions(exceptions.join(' '));
                                                setErrors(newErrors);
                                                return false;
                                            } else {
                                                setSubmitExceptions('');
                                                setErrors([]);
                                            }

                                            invoice.filename = t('invoicing.filename')
                                                .replace('%sellerName%', invoice.sellerName)
                                                .replace('%invoiceNumber%', invoice.invoiceNumber.trim());
                                            invoice.lang = i18n.language.substring(0, 2);
                                            invoice.lastGenerated = Date.now();
                                            const j = JSON.stringify(invoice);
                                            setJson(j);
                                            put(PREFIX_INVOICE + invoice.invoiceNumber.trim().replaceAll(/[^0-9a-zA-Z\.]/g, '_'), j);
                                            put(
                                                PREFIX_BUYER + invoice.buyerName.replaceAll(/[^0-9a-zA-Z\.]/g, '_'),
                                                JSON.stringify({
                                                    oration: invoice.oration,
                                                    buyerName: invoice.buyerName,
                                                    buyerAddress: invoice.buyerAddress,
                                                    buyerEmail: invoice.buyerEmail,
                                                })
                                            );
                                            messageBar.enqueueSnackbar(t('success') + ': ' + t('invoicing.generate'), {
                                                variant: 'success',
                                            });
                                            setVersion(version + 1);
                                            appAction.emit(ACTION_CHECK_SYNC);
                                            setTimeout(() => setGenerating(true), 100);
                                            setTimeout(() => setGenerating(false), 3000);
                                        }}
                                    >
                                        <input type="hidden" name="json" value={json} />
                                        <Tooltip arrow key="pdf" title={t('invoicing.generate')}>
                                            <Button
                                                data-testid="invoicing.generate"
                                                component="button"
                                                role={undefined}
                                                type="submit"
                                                sx={{ padding: '11px', border: 0, height: '100%' }}
                                                disabled={generating}
                                            >
                                                <PictureAsPdfIcon color="success" />
                                                <span className="sm-hide">{t('invoicing.generate')}</span>
                                            </Button>
                                        </Tooltip>
                                    </form>
                                </Box>
                                <Tooltip arrow key="seller" title={t('invoicing.seller')}>
                                    <ToggleButton aria-label={t('invoicing.seller')} value={'seller'} sx={{ border: 0 }}>
                                        <StoreIcon color="primary" />
                                        <span className="sm-hide">{t('invoicing.seller')}</span>
                                    </ToggleButton>
                                </Tooltip>
                                <Tooltip arrow key="layout" title={t('invoicing.layout')}>
                                    <ToggleButton aria-label={t('invoicing.layout')} value={'layout'} sx={{ border: 0 }}>
                                        <PictureInPictureIcon color="primary" />
                                        <span className="sm-hide">{t('invoicing.layout')}</span>
                                    </ToggleButton>
                                </Tooltip>
                            </ToggleButtonGroup>
                        </Box>
                    </>
                }
            />
        </Box>
    );
}
