// see https://www.newbridgefx.com/currency-codes-symbols/',
export enum CurrencySign {
    AED = 'د.م.',
    AFN = '؋',
    ALL = 'Lek',
    AMD = 'Դ',
    ANG = 'ƒ',
    AOA = 'is',
    ARS = '$',
    AUD = '$',
    AWG = 'ƒ',
    AZN = '₼',
    BAM = 'КМ',
    BBD = '$',
    BDT = '৳',
    BES = '$',
    BGN = 'лв',
    BHD = 'ب.د',
    BIF = '₣',
    BMD = '$',
    BND = '$',
    BOB = 'Bs.',
    BOV = 'Mvdol',
    BRL = 'R$',
    BSD = '$',
    BTN = 'Nu',
    BWP = 'P',
    BYN = 'p.',
    BZD = '$',
    CAD = '$',
    CDF = '₣',
    CHF = '₣',
    CLP = '$',
    CNY = '¥',
    COP = '$',
    CRC = '₡',
    CUP = '₱',
    CVE = '$',
    CZK = 'Kč',
    DJF = '₣',
    DKK = 'kr.',
    DOP = '$',
    DZD = 'د.ج ',
    EGP = '£',
    ERN = 'Nfk',
    ETB = 'Br',
    EUR = '€',
    FJD = '$',
    FKP = '£',
    GBP = '£',
    GEL = 'ლ',
    GHS = '₵',
    GIP = '£',
    GMD = 'D',
    GNF = '₣',
    GTQ = 'Q',
    GYD = '$',
    HKD = '$',
    HNL = 'L',
    HRK = 'Kn',
    HTG = 'G',
    HUF = 'Ft',
    IDR = 'Rp',
    ILS = '₪',
    INR = '₨',
    IQD = 'ع.د',
    IRR = '﷼',
    ISK = 'Kr',
    JMD = '$',
    JOD = 'د.ا',
    JPY = '¥',
    KES = 'Sh',
    KGS = 'лв',
    KHR = '៛',
    KMF = 'FC',
    KPW = '₩',
    KRW = '₩',
    KWD = 'د.ك ',
    KYD = '$',
    KZT = '〒',
    LAK = '₭',
    LBP = 'ل.ل ',
    LKR = 'Rs',
    LRD = '$',
    LSL = 'L',
    LYD = 'ل.د ',
    MAD = 'د.م.',
    MDL = 'L',
    MGA = 'MK',
    MKD = 'ден',
    MMK = 'K',
    MNT = '₮',
    MOP = 'P',
    MRU = 'UM',
    MUR = '₨',
    MVR = 'ރ ',
    MWK = 'MK',
    MXN = '$',
    MXV = '-',
    MYR = 'RM',
    MZN = 'MTn',
    NAD = '$',
    NGN = '₦',
    NIO = 'C$',
    NOK = 'kr',
    NPR = '₨',
    NZD = '$',
    OMR = '﷼',
    PAB = 'B/.',
    PEN = 'S/.',
    PGK = 'K',
    PHP = '₱',
    PKR = '₨',
    PLN = 'zł',
    PYG = '₲',
    QAR = 'ر.ق ',
    RON = 'L',
    RSD = 'din',
    RUB = '₽',
    RWF = '₣',
    SAR = 'ر.س ',
    SBD = '$',
    SCR = 'Rs',
    SDG = '£',
    SEK = 'kr',
    SGD = '$',
    SHP = '£',
    SLL = 'Le',
    SOS = 'Sh',
    SRD = '$',
    SSP = 'SS£',
    STN = 'Db',
    SYP = '£',
    SZL = 'L',
    THB = '฿',
    TJS = 'ЅМ',
    TMT = 'm',
    TND = 'د.ت ',
    TOP = 'T$',
    TRY = 'TL',
    TTD = '$',
    TWD = 'NT$',
    TZS = 'Sh',
    UAH = '₴',
    UGX = 'Sh',
    USD = '$',
    UYU = '$',
    UZS = 'лв',
    VEF = 'Bs.',
    VND = '₫',
    VUV = 'Vt',
    WST = '$',
    XAF = '₣',
    XCD = '$',
    XOF = 'F',
    XPF = '₣',
    YER = '﷼ ',
    ZAR = 'R',
    ZMW = 'ZK',
    ZWL = '$',
}

export function getCurrencySign(currency: string) {
    if (CurrencySign[currency]) {
        return CurrencySign[currency];
    }
    return '';
}
